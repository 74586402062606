.catalog {
    &__header {
        background-color: $lighter-gray;
        
        &-back {
            border-radius: 0px 0px 80px 80px;
            padding: 40px 0;
            background-color: $white;
        }

        .--subtitle {
            @include font-size(12, 18, 700);
            margin-bottom: 8px;
        }

        @include device-md {
            &-back {
                border-radius: 0px 0px 40px 40px;
                padding: 30px 0;
            }

            .--subtitle {
                margin-bottom: 0;
            }
        }
    }

    &__body {
        background-color: $lighter-gray;
        padding-top: 0;
    }

    &__grid {
        display: grid;
        grid-template-columns: .5fr 1.5fr;
        align-items: flex-start;
        gap: 50px;

        @include device-lg {
            grid-template-columns: 0.7fr 1.3fr;
        }

        @include rwd(960) {
            display: block;
        }

        &__filters-desk {
            .filters-form {
                margin-top: 20px;
            }

            @include rwd(960) {
                display: none;
            }
        }

        &__header {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px;
            margin-bottom: 30px;

            .results {
                @include font-size(16, 20, 700);
            }

            .order {
                display: flex;
                flex-flow: row wrap;
                align-items: baseline;
                justify-content: flex-end;
                gap: 10px;
            }

            @include device-sm {
                .order {
                    font-size: 12px;
                }
            }
        }

        &__filter-controls {
            display: none;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 30px;

            @include rwd(960) {
                display: flex;
            }
        }

        &__filter-badges {
            display: none;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            margin-bottom: 30px;

            &:empty {
                display: none;
            }

            @include rwd(960) {
                display: flex;
            }
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: max-content;
            gap: 16px;

            @include rwd(1200) {
                grid-template-columns: 1fr 1fr;
            }

            @include rwd(960) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include device-md {
                grid-template-columns: 1fr 1fr;
            }

            @include device-sm {
                grid-template-columns: 1fr;
            }
        }

        &__pagination {
            margin-top: 24px;
            padding-top: 40px;
            border-top: 1px solid $mid-gray;

            .paginator {
                justify-content: flex-end;
            }
        }
    }

    &__no-results {
        @include flex-center(column);
        min-height: 350px;

        h5 {
            @include font-size(18, 28, 700);
            margin-bottom: 8px;
        }
        p {
            @include font-size(16, 24);
            color: $gray;
        }

        @include device-sm {
            min-height: 180px;
        }
    }

    &__card {
        background-color: $white;
        border-radius: 16px;
        overflow: hidden;
        transition: box-shadow $timming ease-in-out;
        height: 100%;

        &:hover {
            box-shadow:
                0px 2.3px 2.7px -10px rgba(0, 0, 0, 0.024),
                0px 6.4px 7.5px -10px rgba(0, 0, 0, 0.035),
                0px 15.4px 18.1px -10px rgba(0, 0, 0, 0.046),
                0px 51px 60px -10px rgba(0, 0, 0, 0.07);
        }
        
        &__img {
            display: block;
            min-height: 220px;
            background: no-repeat center center;
            background-size: cover;
            display: flex;
            align-items: flex-end;
            padding: 16px 24px;
        }

        &__description {
            padding: 15px 24px 24px;
            display: flex;
            flex-flow: column nowrap;
            height: calc(100% - 220px);

            .body-type {
                @include font-size(10, 12, 700, 10); 
                text-transform: uppercase;
            }

            .details {
                @include font-size(12, 18, 400);
                color: $gray;
                flex-grow: 1;
            }

            .price {
                display: block;
                margin-top: 8px;
                @include font-size(20, 24, 700);
            }
        }
    }
}

@import "./filters-form";
@import "./filters-form-mob";