.accordion-bg {
    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    &__item {
        .accordion-dropdown {
            display: block;
        }
        .accordion-upward, .accordion-bg__inner {
            display: none;
        }


        &.active {
            .accordion-dropdown {
                display: none !important;
            }
            .accordion-upward, .accordion-bg__inner {
                display: block !important;
            }
        }
    }
    &__inner {
        display: none;

        margin: 16px 0 40px;
        width: 100%;
        &__box{
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
    &__btn {
        border: none;
        margin: 0;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 7px 22px 7px 16px;
        background-color: #F1ECFF;
        border-radius: 8px;
        width: 100%;
        cursor: pointer;                
        span {
            color: $primary-color;
            @include font-size(16, 24, 700);
        } // label
    }
}
