.banner-carousel {
    padding-top: 0;
    background-color: #f7f7f7;

    &__swiper {
        padding-bottom: 40px !important;

        // .swiper-wrapper {
        //     padding: 0 80px;
        // }

        .swiper-pagination {
            bottom: 0;

            .swiper-pagination-bullet-active {
                background: #454545;
                width: 40px;
                height: 10px;
                border-radius: 5px;
            }
        }

        .swiper-button-next {
            height: 40px;
            width: 40px;
            background-color: #fff;
            border-radius: 50%;
            color: #fff;
            transform: rotate(180deg);
            
            img {
                position: absolute;
                left: 12px;
            }

            &:after {
                display: none;
            }
        }

        .swiper-button-prev {
            height: 40px;
            width: 40px;
            background-color: #fff;
            border-radius: 50%;
            color: #fff;
            
            img {
                position: absolute;
                left: 12px;
            }

            &:after {
                display: none;
            }
        }

        .swiper-slide {
            max-height: 240px;
            width: 100% !important;
            display: flex;
            justify-content: center;
            // margin: 0 104px !important;
            
            a {
                border-radius: 16px;
                overflow: hidden;

                .img-mobile {
                    display: none;
                }

                @include device-lg {
                    .img-desktop {
                        display: none;
                    }
                    .img-mobile {
                        display: block;
                    }
                }
            }
        }
    }
}