.login {
    .container {
        .content {

            .primary-link {
                display: block;
                text-align: center;
            }

            .primary-btn {
                margin: 24px auto 32px auto;
            }

            .--link-s {
                color: $primary-color;
                margin-left: 5px;
            }

            .p-and-a {
                display: flex;
                justify-content: center;
            }
        }
    }


    @include device-md() {
        .container {
            .content {

                h1 {
                    display: block;
                }

                .--link-s {
                    color: #E30018;
                }
            }
        }
    }
}

.social-login{
    margin-bottom: 32px;
    &__separator {
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 32px;
        p {
            @include font-size(14, 20, 400);
            // color: ;
            background-color: white;
            padding: 0 8px;
            display: inline-block;
            margin-bottom: 0 !important;
            z-index: 1;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 2px;
            background-color: $light-gray;
    
        }
    }
    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}