.tabs-profile {
    background-color: $lighter-gray;
    padding-top: 0;
}

.profile {
    &__empty-tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 610px;
        margin: 120px auto;
        h2 {
            font-weight: 700;
            font-size: 48px;
            line-height: 56px;
            text-align: center;
            letter-spacing: -0.02em;
            margin-bottom: 12px;
            @include device-md {
                font-size: 32px;
                line-height: 40px;
            }
        }
        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: $gray;
            margin-bottom: 32px;
            @include device-md {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    &__header {
        background-color: $lighter-gray;
        
        &-back {
            border-radius: 0px 0px 80px 80px;
            padding: 40px 0;
            background-color: $white;
        }

        &__name {
            grid-area: name;
        }

        &__cta{
            grid-area: cta;
            @include font-size(14, 20, 700);
            text-decoration: underline;
            color: $primary-color;
        }

        .--subtitle {
            @include font-size(12, 18, 700);
            margin-bottom: 8px;
            grid-area: sub;
        }

        &__box{
            display: grid;
            grid-template-columns: auto auto;
            justify-content: space-between;
            align-items: center;
            column-gap: 15px;
            grid-template-areas:
            "sub ."
            "name cta";

            @include device-md{
                grid-template-areas:
                "sub ."
                "name name"
                ". cta";
            }
        }

        @include device-md {
            &-back {
                border-radius: 0px 0px 40px 40px;
                padding: 30px 0;
            }

            .--subtitle {
                margin-bottom: 0;
            }
        }
    }

    &__content {
        .login-post-signup {
            .container {
                min-height: auto;

                .content {
                    grid-column: 9/span 7;
                    h1, p {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.contact-data {
    text-align: center;
    margin-bottom: 8px;

    h5 {
        @include font-size(16, 24, 700);
        text-transform: uppercase;
        color: $primary-color;
    }

    ul {
        @include font-size(14, 20);
        color: $gray;
    }
}

@import "./accordion";
@import "./card-list";
@import "./tabs";
@import "./modals";
@import "./comissions_table";