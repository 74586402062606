.post-car {
    @include main-grid();
    min-height: calc(100vh - 70px);
    align-content: center;

    &_text {
        grid-column: 7 / span 11;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;

        p {
            color: $gray;
            margin-top: 24px;

            &.mt-12 {
                margin-top: 12px;
            }
        }

        &_disclaimer {
            max-width: 400px;
            margin: 46px auto;

            .disclaimer-title {
                @include font-size(18, 28, 700, 0);
                text-align: center;
                margin-bottom: 16px;
                margin-top: 0;
                color: $black;
            }

            .disclaimer-img {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: left;

                svg {
                    max-width: 80px;
                    max-height: 80px;
                    min-height: 80px;
                    min-width: 80px;
                }

                div {
                    p {
                        @include font-size(16, 24, 400, 0);
                        color: $gray;
                        margin-top: 0;
                        margin-left: 24px;

                        &:first-of-type {
                            margin-bottom: 8px;
                        }
                    }
                }

            }
        }
    }

    &_form {
        grid-column: 7 / span 11;
        text-align: center;
        margin-top: 80px;

        .ego-form__field {
            max-width: 400px;
            min-width: 343px;
            margin-left: auto;
            margin-right: auto;
        }   

        .post-car_form_error {
            .ego-form__field__error.--active {
                padding: 46px 72px;
                position: relative;
                top: 0;
                background-color: #F7F7F7;
                margin-top: 48px;
                border-radius: 16px;
                color: #191919;
                @include font-size(18, 28, 400, 0);
                text-align: center;
                background-image: none !important;
                justify-content: center;
            }
            p {
                @include font-size(14, 20, 700, 0);
                width: 300px;
                margin: 24px auto;
                font-family: 'ToyotaType', sans-serif;
            }
        }
    }

    &_model {
        grid-column: 7 / span 11;
        background-color: #F7F7F7;
        padding: 24px;
        max-width: 400px;
        min-width: 343px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 16px;
        margin-bottom: 24px;

        .--subtitle {
            color: $primary-color-lighter;
        }
    }

    &_btn {
        .btn-large {
            max-width: 400px;
            min-width: 343px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        .primary-link {
            margin-top: 16px;
        }
        .loading-react {
            max-width: 400px;
            min-width: 343px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            align-items: center;
            justify-content: center;
            display: flex;
            column-gap: 8px;
            svg {
                animation: spin 2s infinite linear;
            }
        }

        .loading-react-fifth {
            align-items: center;
            justify-content: center;
            display: flex;
            column-gap: 8px;
            width: 194.5px !important;
            min-width: unset !important;
            svg {
                animation: spin 2s infinite linear;
            }

            @include device-sm() {
                width: 100%;
            }
        }

        @keyframes spin {
            from {transform:rotate(0deg);}
            to {transform:rotate(360deg);}
        }

    }

    &_link {
        display: block;
        margin-top: 16px;
    }

    &_box1 {
        display: flex;
        border: 2px solid #E8E8E8;
        border-radius: 16px;
        text-align: left;
        margin-bottom: 8px;
        overflow: hidden;

        &_left {
            padding: 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            div {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 24px;
            }

            .--text-xs {
                margin-bottom: 0px;
                margin-top: 40px;
                padding-top: 8px;
                border-top: 1px solid #EEEEEF;
                font-style: italic;
                color: $gray;
            }
            .textlink {
                font-size: 12px;
                margin-top: 8px;
                margin-bottom: 0;
                text-align: end;
                text-decoration: underline;
                cursor: pointer;
                z-index: 2;
            }
            .commission-detail {
                padding: 12px 0 6px;
                font-size: 12px;
                display: none;
                gap: 8px;
                p {
                    margin: 0;
                    display: flex;
                    justify-content: space-between;
                }
                &__final-price {
                    font-size: 14px;
                    font-weight: 800;
                }
                &.active {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &_right {
            background-color: #F7F7F7;
            color: $primary-color;
            padding: 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: relative;

            .tcuv-container {
                display: flex;
                align-items: center;
                margin-top: 12px;

                .tcuv {
                    height: 24px;
                    margin-right: 8px;
                }

                .info {
                    width: 8px;
                    margin-left: 2px;
                }
            }

            .tcuv-tooltip {
                display: none;
                width: 326px;
                position: absolute;
                top: 10px;
                left: 14px;
            }

            .tcuv-container:hover + .tcuv-tooltip{
                display: block;
            }

            .loading {
                animation: spin 2s infinite linear;
                height: 40px;
            }

            @keyframes spin {
                from {transform:rotate(0deg);}
                to {transform:rotate(360deg);}
            }
        }
    }

    &_box2 {
        border: 2px solid #E8E8E8;
        border-radius: 16px;
        text-align: left;
        margin-top: 16px;
        margin-bottom: 16px;
        padding: 41px 32px;
        
        .container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            overflow: auto;

            .text-dropzone {
                @include font-size(14, 14, 400, 0);
                color: $gray;
                margin-top: 33px;
            }

            .dropzone {
                grid-column: 2;
                grid-row: 1;
                width: max-content;
                align-self: center;
                justify-self: center;
            }

            aside {
                grid-column: 1 / -1;
                grid-row: 2;
                margin-top: 60px;
            }

            .upload-div {
                display: flex;
                grid-column: 1;
                grid-row: 1;
    
                img, svg {
                    margin-right: 16px;
                }
    
                p {
                    margin-bottom: 8px;
                }
            }

            .grid-sortable {
                grid-column: 1/-1;
                display: grid;
                grid-auto-flow: column;
                overflow-x: auto;
                justify-self: start;

                .container-btn-img {
                    position: relative;

                    &:hover {
                        .thumbInner {
                            filter: brightness(0.3);
                            cursor: move;
                        }
                        .delete-icon {
                            display: block;
                            width: 20px;
                            position: absolute;
                            z-index: 500;
                            top: 50%;
                            right: 50%;
                            // transform: translate(-50%, -50%);
                            filter: brightness(50) saturate(0);
                            cursor: pointer;
                        }
                    }

                    .delete-icon {
                        display: none;
                    }
                }
            }

            .uploading {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 126px;
                height: 126px;
                svg {
                    animation: spin 2s infinite linear;
                }
            }
        }

        .btn-large {
            max-height: 40px;
        }

        .thumbsContainer {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 16px;
          grid-column: 1/-1;
        }

        .thumb {
          display: inline-flex;
          border-radius: 16px;
          margin-bottom: 8px;
          margin-top: 16px;
          margin-right: 16px;
          width: 126px;
          height: 126px;
          box-sizing: border-box;
          overflow: hidden;
          position: relative;
        }

        .thumbInner {
          display: flex;
          min-width: 0px;
          overflow: hidden;
        }

        @include device-md(){
            margin-top: 32px;
            margin-bottom: 32px;
        }
    }

    &_box3 {
        display: flex;
        flex-direction: column;
        border: 2px solid #E8E8E8;
        border-radius: 16px;
        text-align: left;
        margin-bottom: 8px;
        padding: 41px 32px;
        justify-content: space-between;
        align-items: flex-start;

        p {
            margin-bottom: 24px;
        }

        textarea {
            width: 100%;
            resize: none;
            border: 1.5px solid #A7A7A8;
            border-radius: 8px;
            padding: 10px 14px;
            font-family: 'ToyotaType', sans-serif;
            @include font-size(16, 24, 400);
            outline: transparent;
        }
    }

    &_fifth_step {
        @include main-grid;
        .post-car_fifth_step_success {
            grid-column: 1 / -1;
            background-color: $success-color-soft;
            color: #027A48;
            height: 50px;
            border-radius: 8px;
            align-items: center;
            justify-content: space-between;
            padding: 17px;

            span {
                display: flex;
                align-items: center;
            }

            img, svg {
                width: 13.33px;
                height: 13.33px;
                margin-right: 17px;
            }

            button {
                border: none;
                background-color: transparent;
                justify-self: flex-end;
            }
        }

        h2 {
            grid-column: 1 / -1;
            margin-top: 25px;
            margin-bottom: 48px;
        }

        .back-button {
            grid-column: 1 / -1;
            display: block;
            margin-bottom: 24px;
            margin-top: 24px;

            img, svg {
                width: 11.67px;
                margin-right: 12px;
            }
        }

        &_left{
            grid-column: 1 / span 15;
            height: 500px;
            margin-bottom: 80px;

            .mySwiper2 {
                width: 100%;
                height: 460px;
                margin-bottom: 16px;
                border-radius: 18px;

                img, svg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .swiper-button-next, .swiper-button-prev {
                    border-radius: 50%;
                    background-color: white;
                    opacity: 0.6;
                    width: 36px;
                    height: 36px;
                }
                .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
                    content: 'next';
                    color: black;
                    font-size: 15px;
                    font-weight: bold;
                }

                .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
                    content: 'prev';
                    color: black;
                    font-size: 15px;
                    font-weight: bold;
                }

                .swiper-pagination-fraction {
                    color: $black;
                    @include font-size(16, 16, 700, 2);
                    font-family: "ToyotaType", sans-serif;
                    padding: 8px 16px;
                    border-radius: 24px;
                    background-color: white;
                    opacity: 0.6;
                    width: max-content;
                    margin-left: calc(50% - 40px);
                    margin-bottom: 24px;
                }
            }

            .mySwiper {
                width: 100%;

                .swiper-slide {
                    width: 126px;
                    height: 126px;
                    border-radius: 16px;
                    overflow: hidden;

                    img, svg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .description {
                height: 56px;
                border-bottom: 1px solid #BEBEC0;
                display: flex;
                align-items: center;
                margin-bottom: 24px;
                margin-top: 16px;
            }
        }
        
        &_right{
            grid-column: 17 / -1;
            height: 500px;
            padding: 24px 0 0 16px;
            margin-top: -78px;

            &_1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 32px;
                p {
                    @include font-size(14, 14, 700, 0);
                    color: $primary-color-darker;
                    background-color: $primary-color-light;
                    border-radius: 16px;
                    padding: 4px 10px;
                }
                .--link-s {
                    color: #D92D20;
                    text-decoration: underline;
                    display: flex;
                    align-items: flex-end;
                    cursor: pointer;

                    img, svg {
                        width: 16.77px;
                        height: 16.77px;
                        margin-left: 10.5px;
                    }
                }
            }

            &_2 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .--subtitle {
                    color: $primary-color-lighter
                }
            }

            &_3 {
                display: grid;
                grid-template-columns: repeat(2, auto);
                grid-template-rows: repeat(2, auto);
                margin-bottom: 16px;

                h1 {
                    grid-row: 1;
                }
                .model {
                    font-weight: 400;
                    grid-row: 2;
                    grid-column: 1;
                }
                .--link-s {
                    grid-row: 2;
                    grid-column: 2;
                    text-decoration: underline;
                    color: $primary-color;
                    align-self: end;
                    display: flex;
                    align-items: flex-end;
                    cursor: pointer;

                    img, svg {
                        width: 16.77px;
                        height: 16.77px;
                        margin-left: 8px;
                    }
                }
            }

            &_4 {
                p {
                    padding-top: 16px;
                    padding-bottom: 16px;
                    border-top: 1px solid #DEDEDF;
                    color: $gray;
                }
            }

            &_5 {
                background-color: #F7F7F7;
                border-radius: 16px;
                padding: 24px 16px;
                font-weight: 700;
                color: $gray;
                margin-top: 16px;

                .title {
                    padding-bottom: 16px;
                    border-bottom: 1px solid #DEDEDF;
                }

                .subtitle {
                    margin-top: 16px;
                    margin-bottom: 16px;
                }

                span {
                    background: $primary-color;
                    border: 1px solid $primary-color;
                    border-radius: 16px;
                    padding: 16px 24px;
                    display: flex;
                    justify-content: space-between;
                    color: $white;
                }

                button {
                    border-radius: 16px;
                    padding: 16px 24px;
                    border: 1px solid $primary-color;
                    margin-top: 16px;
                    background-color: $white;
                    @include font-size(18, 28, 700, 0);
                    color: $primary-color;
                    width: 100%;
                    text-align: left;
                }
            }
        }

        .state_mobile {
            display: none;
        }

        .menu_mobile {
            display: none;
        }
    }

    .disabled {
        pointer-events: none;
    }

    .modal_recomendations_container, .modal_delete_container {
        background-color: rgba(0, 0, 0, 0.4);
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }
    
    .modal_recomendations, .modal_delete {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 510px;
        background-color: $white;
        padding: 24px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1001;
        text-align: center;
    
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
        }

        p {
            margin-bottom: 32px;
        }

        .line {
            width: 100%;
            height: 1px;
            background-color: #E8E8E8;
            margin-bottom: 16px;
        }

        .btn-large {
            width: 100%;
            max-width: 100%;
            margin-bottom: 16px;
        }
    
        .examples {
            width: 100% !important;
            height: 100% !important;
            margin-bottom: 32px;
        }
    
        @include device-sm() {
            margin-left: auto;
            margin-right: auto;
            width: 343px;
        }
    }

    @include device-lg(){
        &_text, &_form {
            grid-column: 1 / -1 !important;
        }

        &_fifth_step {
            grid-template-rows: repeat(2, max-content);
            &_left {
                grid-column:  1 / -1;
                height: auto;
            }
            &_right {
                grid-column:  1 / -1;

                &_1 {
                    display: none;
                }

                &_2 {
                    img, svg {
                        display: none;
                    }
                }

                &_3 {
                    .link {
                        display: none;
                    }
                }
            }

            .state_mobile {
                display: block;
                @include font-size(14, 14, 700, 0);
                    color: $primary-color-darker;
                    background-color: $primary-color-light;
                    border-radius: 16px;
                    padding: 4px 10px;
            }

            .menu_mobile {
                display: flex;
                grid-column: 7;
                justify-self: end;
                position: relative;

                &_open {
                    display: flex;
                    position: absolute;
                    right: 0;
                    top: 25px;
                    flex-direction: column;
                    z-index: 5;
                    width: 178px;
                    box-shadow: 0px 4px 8px -2px rgb(16 24 40 / 10%), 0px 2px 4px -2px rgb(16 24 40 / 6%);
                    border-radius: 8px;
                    background-color: $white;

                    .link{
                        padding: 20px 14px;
                        span {
                            display: flex;
                            justify-content: space-between;

                            img, svg {
                                height: 16.7px;
                            }
                        }
                    }

                    .link:first-child {
                        border-bottom: 1px solid #EEEEEF;
                    }
                }
            }
        }
    }

    @include device-md(){
        align-content: start;
        padding-top: 60px;

        &_text {
            margin-top: 32px;
        }

        &_form {
            margin-top: 32px;

            .ego-form__field{
                min-width: 327px;
            }
        }

        &_btn {
            position: absolute;
            bottom: 0;
            right: 50%;
            transform: translateX(50%);
        }

        &_box1 {
            border: none;
            flex-direction: column;
            grid-column: 1 / -1;
            align-items: center;
    
            &_left {
                padding: 0px;
                margin-bottom: 16px;
            }
    
            &_right {
                padding: 32px 8px;
                margin-top: 16px;
                text-align: center;

                .--text-m {
                    @include font-size(14, 20, 400, 0);
                }
            }
        }
    
        &_box2 {
            text-align: center;
            padding: 32px 16px;
    
            .container {
                .upload-div {
                    grid-column: 1 / -1;
                    grid-row: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img, svg {
                        margin: 0 auto;
                    }
                }

                .dropzone {
                    grid-column: 1 / -1;
                    grid-row:2;
                    width: 100%;
                    max-width: 400px;
                    p {
                        margin: 0 auto;
                    }
                }
    
                img, svg {
                    margin-right: auto;
                    margin-left: auto;
                }
    
                p {
                    margin-top: 8px;
                }

                .grid-sortable {
                    grid-template-columns: repeat(auto-fill, 140px);
                    grid-auto-flow: inherit;
                    justify-items: center;
                    align-items: center;
                    justify-self: unset;
                }

                .thumb {
                    margin-right: none;
                }

                .thumb:hover{
                    .thumbInner {
                        filter: brightness(1);
                        cursor: move;
                    }
                    .delete-icon {
                        display: none
                    }
                }
        
                .delete-icon {
                  display: none;
                }
            }
    
            .btn-large {
                margin-top: 24px;
            }
        }
    
        &_box3 {
            border: none;
            padding: 0;
    
            p {
                margin-bottom: 16px;
            }
    
            textarea {
                margin-bottom: 69px;

                @include device-md(){
                    margin-bottom: 40px;
                }
            }
        }
    }

    @include rwd(670){
        margin-top: 66px;
    }

    @include device-sm(){
        &_fifth_step {
            &_left {
                height: auto;

                .mySwiper2 {
                    width: 100%;
                    height: 194px;
                    margin-top: 21px;
                }

                .mySwiper {
                    height: 87.5px;
                    img{
                        height: 87.5px;
                    }
                }
            }
        }

        &_box2 {
            text-align: center;
            padding: 32px 16px;
    
            .container {
                .grid-sortable {
                    grid-template-columns: repeat(auto-fill, 102px);
                    grid-auto-flow: inherit;
                    justify-items: center;
                    align-items: center;
                    justify-self: unset;
                }

                .thumb {
                    margin-right: none;
                    width: 95px;
                    height: 95px;
                }
            }
        }

    }
}

@include device-sm(){
    section.post-car {
        padding-top: 0 !important;
    }
}

.precios_fotos {
    .post-car_form {
        grid-column: 5 / span 15;

        .post-car_third_step_success, .post-car_fourth_step_success {
            background-color: $success-color-soft;
            color: #027A48;
            height: 50px;
            border-radius: 8px;
            align-items: center;
            justify-content: space-between;
            padding: 17px;
    
            span {
                display: flex;
                align-items: center;
                text-align: left;

                .--link-s {
                    font-weight: 400;
                }
            }
    
            img, svg {
                width: 13.33px;
                height: 13.33px;
                margin-right: 17px;
            }
    
            .btn-large {
                border: none;
                background-color: transparent;
                justify-self: flex-end;

                img, svg {
                    width: 10px;
                    height: 10px;
                }
            }

            @include device-md() {
                height: auto;
                margin-bottom: 24px;
            }
        }
    }

    .btn_and_msg {
        display: flex;
        flex-direction: column;
    
        .btn_group {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 32px;
        
            .third_step_btn {
                margin-bottom: 16px;
                .btn-large {
                    max-width: 400px;
                    min-width: 343px;
                    width: 100%;
                    display: block;
                }
        
                @include device-md(){
                    margin-top: 0px;
                }
            }
        }

        @include device-md(){
            flex-direction: column-reverse;
        }
    }
}

.features_container{
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 80px 0 48px 0;  
    grid-template-columns: 1fr 1fr 1fr;
    grid-row: 2;
    grid-column: 5/20;
    @include rwd(1300){
        grid-column: 4/20;
    }
    @include rwd(1200){
        grid-column: 3/20;
    }
    @include device-lg{
        grid-column: 1/-1;
    }
    @include rwd(850){
        display: flex;
        flex-direction: column;
    }
    @include device-sm{
        margin: 24px 0 0 0;
    }
}

.features_box{
    display: flex;
    align-items: center;
    width: 254px;
    height: 72px;
    border-radius: 10px;
    border: 1px solid #D8D8D8;
    margin: 8px 6px;
    padding-left: 24px;
    cursor: pointer;
    @include rwd(850){
        width: 100%;
        margin: 8px 0;
        justify-content: center;
    }
    &.selected{
        box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
        color: $primary-color;
        border: 1px solid $primary-color;
        background-color: $primary-color-soft;
    }
}

.features_btn{
    grid-row: 3;
    grid-column: 9/16;
    max-width: 400px;
    margin-bottom: 16px;
    justify-self: center;
    @include device-lg{
        grid-column: 1/-1;
    }
    @include rwd(850){
        position: fixed;
        bottom: 0;
    }
}

.features_view_container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 32px;
}

.feature_view{
    display: flex;
    height: 24px;
    width: 260px;
    margin: 10px 10px 10px 0;
    align-items: center;
    @include device-sm{
        width: 100%;
    }
}