.filters {
    background-repeat: no-repeat;
    background-size: cover;
    
    &_container {
        padding-top: 32px;
        margin-bottom: -33px;
        @include main-grid();

        h3 {
            @include font-size(32, 40, 700, -2%);
            text-align: center;
            margin-bottom: 24px;
            color: #FFF;
            grid-column: 2 / span 21;

            span {
                color: #B09BEA;
            }
        }

        &__filters {
            background-color: #FFF;
            border-radius: 16px;
            padding: 32px 40px 8px 40px;
            grid-column: 2 / span 21;
        }

        &__logo {
            grid-column: 1 / -1;
            grid-row: 3;
            max-width: 280px;
            margin-top: 24px;
            justify-self: center;
        }    
    }

    &__form {
        display: flex;
        gap: 25px;
        
        button {
            height: max-content;
        }

        .ego-form__field {
            margin-bottom: 24px;
        }
    }

    @include rwd(1024) {
        &_container {
            h3, &__filters {
                grid-column: 1 / -1;
            }
        }
    }

    @include rwd(719) {
        &_container {
            margin-bottom: -20px;

            &__filters {
                padding: 24px 20px;
                margin-left: 8px;
                margin-right: 8px;
            }

            h3 {
                @include font-size(26, 34, 700, -2%);
                margin-bottom: 32px;
            }
        }

        &__form {
            flex-direction: column;
            gap: 0;
        }
    }
}