.catalog__grid__filters-mob {
    display: block;
    position: fixed;
    inset: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    background-color: $white;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow:
        0px 0px 5.3px rgba(0, 0, 0, 0.028),
        0px 0px 17.9px rgba(0, 0, 0, 0.042),
        0px 0px 80px rgba(0, 0, 0, 0.07);
    transform: translate3d(0px, 100%, 0px);
    transition: transform $timming ease-in-out;


    &.--open {
        transform: translate3d(0, 0%, 0);
    }

    #filtersFormMob {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        height: 100%;

        .filters-mob {
            $filters-mob: &;

            &__header {
                padding: 22px 16px;
                display: flex;
                flex-flow: row nowrap;
                gap: 10px;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $light-gray;
    
                &__close {
                    content: '';
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    background: url('../images/dropdown-arrow.svg') no-repeat center center transparent;
                    background-size: contain;
                    border: 0;
                    outline: none;
                }
            }
            
            &__body {
                flex-grow: 1;
                overflow: auto;
            }

            &__tabs {
                overflow: hidden;
    
                &--tab {
                    width: 100%;
                    overflow: hidden;
    
                    .tab-label {
                        display: flex;
                        justify-content: space-between;
                        padding: 14px 16px;
                        @include font-size(14, 19, 700);
                        background: $white;
                        border-bottom: 1px solid $light-gray;
                        cursor: pointer;
    
                        &::after {
                            content: "";
                            width: 22px;
                            height: 22px;
                            background: url('../images/dropdown-arrow.svg') no-repeat center center transparent;
                            background-size: contain;
                        }
                    }
    
                    .tab-content {
                        max-height: 0;
                        padding: 0 16px;
                        background: $lighter-gray;
                        transition: all $timming ease-in-out;

                        .ego-form__field {
                            margin-bottom: 32px;
                
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        .two-columns {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 5px;
            
                            .ego-form__field {
                                margin-bottom: 0;
                            }
                        }

                        .colors-grid {
                            display: flex;
                            flex-flow: row wrap;
                            row-gap: 7px;
                            column-gap: 3px;
                            margin-bottom: 15px;
                    
                            .--radio {
                                min-width: 30px;
                                width: auto;
                                margin-bottom: 0;
                            }
                        }
                    }
    
                    .tab-trigger {
                        position: absolute;
                        opacity: 0;
                        z-index: -1;
                        
                        &:checked {
                            + .tab-label {
                                &::after {
                                    transform: rotate(180deg);
                                }
                            }
                            ~ .tab-content {
                                max-height: 100vh;
                                padding: 16px;
                            }
                        }
                    }
                }
            }

            &__footer {
                padding: 22px 16px;
                border-top: 1px solid $light-gray;
            }
        }
    }

}