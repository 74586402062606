.header-post-car{
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: $white;
    padding: 21px 0px;
    width: 100%;
    border-bottom: 1px solid $light-gray;

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 9;

        .btn-small.salir {
            @include rwd(670){
                position: absolute;
                right: 16px;
                width: 62px;
            }
        }

        .btn-small.volver {
            display: none;

            @include rwd(910){
                display: block;
                width: 74px;
            }
        }

        .steps {
            display: none;
            color: $primary-color;

            @include rwd(850){
                display: block;
                // margin-left: auto;
                // margin-right: auto;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__logo-container{
        max-width: 170px;
        a {
            img {
                @include image-responsive(block);
            }
        }
        
        @include rwd(910){
            .logo {
                display: none;
            }
        }
    }

    &__primary-nav{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 75%;
        margin-left: 10px;

        .nav-link {
            border-bottom: 1px solid #D2C7EF;
            width: 192px;
            padding-bottom: 8px;
            color: $gray;
        }

        .icon-edit {
            background-color: #EEEEEF;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            padding: 2px;
            position: relative;
            display: inline-block;
            margin-right: 8px;

            img {
                filter: contrast(0);
                position: absolute;
                left: 3px;
            }
        }

        .icon-done {
            display: none;
            background-color: $success-color-soft;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            padding: 2px;
            position: relative;
            margin-right: 8px;

            img {
                position: absolute;
                left: 3px;
                top: 3px;
            }
        }
    
        .--active{
            color: $primary-color;
            .icon-edit {
                background-color: #D2C7EF;
                img {
                    filter: none;
                }
            }
        }

        .--done{
            color: $success-color;
            .icon-done {
                display: inline-block;
            }

            .icon-edit {
                display: none;
            }
        }

        @include rwd(1330){
            li {
                width: auto;
                max-width: 192px;
                min-width: 100px;
            }
        }

        @include rwd(1220){
            justify-content: space-evenly;

            .nav-link {
                border-bottom: 1px solid #D2C7EF;
                width: 140px;
            }
        }

        @include rwd(850){
            display: none;
        }
    }
}