.alert {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: max-content;
    border-radius: 8px;
    margin: 0 auto 24px;
    text-align: left;
    background-color: $light-gray;
    color: $gray;
    padding: 14px 17px;
    @include font-size(14, 20, 700);
    transition: all $timming ease-in-out;

    &.--small {
        padding: 5px 10px;
    }

    &.--info,
    &.--warning {
        background-color: $primary-color-light;
        color: $primary-color;
    }
    
    &.--success {
        background-color: $success-color-soft;
        color: $success-color;
    }

    &.--danger {
        background-color: $danger-color-soft;
        color: $danger-color;
    }

    &.--dismissible {
        position: relative;
        padding-right: 50px;

        &:after {
            content: '\00d7';
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 17px;
            transform: translateY(-50%);
            color: currentColor;
            padding: 3px 3px 6px;
            @include font-size(25, 14);
        }
    }

    &.--close {
        opacity: 0;
        transform: translateY(-50%);
    }

    &.--hide {
        display: none;
    }

    &.--no-margin {
        margin: 0;
    }

    &.--floating {
        z-index: 100;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: fit-content;
        margin: auto 0 24px;
    }
    
    .icon {
        height: 1rem;
    }
}