.video {
    background-color: #F7F7F7;
    
    &__header {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 40px;
        max-width: 815px;
        margin-left: auto;
        margin-right: auto;

        @include device-sm{
            margin-bottom: 46px;
        }

        h2 {
            @include font-size(16, 16, 700);
            color: $primary-color;
            text-transform: uppercase
        }

        h3 {
            @include font-size(32, 40, 700);
            @include device-sm{
                @include font-size(26, 34, 700);
            }
        }

        p {
            margin-top: 8px;
            max-width: 608px;
            color: #454545;
            margin-left: auto;
            margin-right: auto;
            @include font-size(18, 28, 400);
        }
    }

    &__big-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
        margin-bottom: 40px;
        @include device-md{
            grid-template-columns: 1fr;
            gap: 24px;
        }

        &:not(:has(.child-2)) {
            grid-template-columns: 1fr;

            .video__container {
                width: 50%;
                justify-self: center;
            }
        }
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;

        &__element {
            height: 320px;
            width: 100%;
            position: relative;
            border-radius: 40px;
            overflow: hidden;
            
            &:hover {
                cursor: pointer;
            }
            
            &__video {
                height: 100%;
                width: 100%;
            }
            
            &__thumbnail {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;

                .gradient {
                    display: none;
    
                    @include device-md {
                        display: block;
                        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }
                }
            }

            .video-play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }

            .video-thumbnail {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
            }

            &__title {
                position: absolute;
                bottom: 32px;
                left: 50%;
                transform: translateX(-50%);
                @include font-size(24, 28, 700);
                text-align: center;
                color: #fff;
                width: 80%;
                z-index: 2;
            }

            &__tag {
                position: absolute;
                top: 32px;
                left: 32px;
                background-color: #B09BEA;
                color: #1A0060;
                text-transform: uppercase;
                @include font-size(12, 18, 700);
                padding: 2px 12px;
                border-radius: 19px;
                z-index: 2;
            }
        }

        &__cta {
            padding: 8px 24px;
            background-color: #3A00D5;
            color: #fff;
            border-radius: 48px;
            width: max-content;
            @include font-size(16, 22, 700);
            align-self: center;
            display: flex;
            margin-top: 24px;

            svg {
                margin-left: 16px;
            }

            p {
                margin-bottom: 2px;
            }
        }
    }
}