
.tabs {
    // overflow: hidden;
    position: relative;
    &-list{
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 16px;
        align-items: center;
        @include device-sm{
            justify-content: space-between;
            gap: 8px;
        }
 
        width: 100%;
        // Oculta scrollbar
        overflow: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
            width: 0 !important;
          }

        &__item {
            display: inline-block;
            padding: 10px 15px;
            flex-shrink: 0;
            &.active {
                border-bottom: 2px solid $primary-color;
                color: $primary-color;
                z-index: 1;
            }
        }
    
        text-align: center;
        @include font-size(16, 22, 700);
    }
    &-buttons {
        position: relative;
        width: 100%;
        margin-bottom: 40px;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom: 2px solid #D8D8D8;
            width: 100%;
        }
    }
    &-panel{
        display: none;
        flex-direction: column;
        gap: 32px;
        animation: contentEffect .5s; // CHECK
    }
    .profile__header__cta{
        position: absolute;
        right: 0;
        top: -34px;
        cursor: pointer;
    }
}

@keyframes contentEffect {
    from {
        opacity: 0;
        transform: translateY(70%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
  }