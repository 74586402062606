.operations {
    background-color: #F5F5F5;
    padding-bottom: 40px;

    .wrapper {
        padding-top: 40px;

        .operations_text {
            margin-bottom: 24px;
        }

        .operations_container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
        }
    }

    @include device-lg(){
        .wrapper {
            .operations_container {
                display: flex;
                flex-direction: column;
            }
        }
    }
}