.badge {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;

    @include font-size(14, 14, 400);
    color: $primary-color;
    background-color: $light-gray;
    padding: 6px 12px;
    border-radius: 12px;

    &.--white {
        background-color: $white;
    }

    &.--dismissible {
        cursor: pointer;

        &:after {
            content: '\00d7';
            @include font-size(21, 13, 400);
        }
    }

    &.--state {
        font-weight: 700;

        &[data-state="draft"] {
            background-color: $mid-gray;
            color: $gray;
        }

        &[data-state="published"] {
            background-color: #D2C7EF;
            color: $primary-color-darker;
        }

        &[data-state="reserved"] {
            background-color: $primary-color-soft;
            color: $primary-color;
        }

        &[data-state="rejected"] {
            background-color: #FEF3F2;
            color: $danger-color;
        }

        &[data-state="completed"] {
            background-color: #ECFDF3;
            color: $success-color;
        }
    }
}