.profile-offers{
    &__card-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__card-box {
        padding: 16px;
        border: 2px solid #E8E8E8;
        border-radius: 16px;

        $card: &;

        &__data {
            display: flex;
            align-items: center;
            gap: 16px;

            .icon {
                display: block;

                &-rejected {
                    display: none;
                }
            }

            .amount {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                display: block;
            }

            span {
                font-size: 12px;
                line-height: 18px;
                display: block;
            }
        }
        &__controls {
            width: 100%;
            margin-top: 17px;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;

            button {
                display: inline-block;
                flex-grow: 1;
            }
        }

        &.--rejected {
            #{$card}__data {
                .icon {
                    display: none;
    
                    &-rejected {
                        display: block;
                    }
                }

                .amount {
                    &::before {
                        content: 'Rechazaste ';
                    }
                }
            }
            #{$card}__controls {
                display: none;
            }
        }
    }
}

.comissionsModal {
    .ego-modal__box {
        max-width: 590px;
        min-width: 340px;

        .ego-modal__box__content-header {
            p {
                margin-top: 8px;
            }
        }
    }
    .ego-form__footer {
        button {
            max-width: 210px;
            margin: 8px auto 0 auto;
        }
    }
    .accordion-container-comissions {
        li:first-child {
            border-bottom: 1px solid #8E6EE3;
        }
    
        li {
            padding-top: 24px;
            padding-bottom: 24px;
    
    
            .description {
                color: $gray;
                margin-right: 48px;
                margin-top: 8px;
            }
    
            .active, .accordion-comissions:hover {
                cursor: pointer;
            }
    
            .accordion-comissions {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;

                p {
                    margin-right: 16px;
                }
    
                .toggle-minus {
                    display: none;
                }
            }
    
            .panel {
                display: none;
                overflow: auto;
            }
        }
    }
}

// .modal_verify_container {
//     background-color: rgba(0, 0, 0, 0.4);
//     height: 100vh;
//     width: 100vw;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 1000;
// }

// .modal_verify {
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 400px;
//     background-color: $white;
//     padding: 24px;
//     border-radius: 16px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     z-index: 1001;
//     text-align: center;

//     h3 {
//         margin-top: 16px;
//         margin-bottom: 8px;
//     }

//     p {
//         margin-bottom: 32px;
//     }

//     .btn-large {
//         width: 100%;
//         max-width: 100%;
//         margin-bottom: 16px;
//     }

//     .examples {
//         width: 100% !important;
//         height: 100% !important;
//         margin-top: 32px;
//         margin-bottom: 32px;
//     }

//     @include device-sm() {
//         margin-left: auto;
//         margin-right: auto;
//         width: 343px;
//     }
// }