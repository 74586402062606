.offers {
    position: relative;
    background-color: #F7F7F7;
    padding-top: 120px;
    padding-bottom: 100px;

    @include device-lg {
        padding-top: 160px;
    }

    @include device-sm {
        padding-top: 120px;
        padding-bottom: 150px;
    }

    .wrapper {
        @include main-grid();
        
        img {
            width: 100%;
        }

        .offers_text {
            grid-column: 2 / span 9;
            grid-row: 1;
            background: $white;
            padding: 24px;
            border-radius: 15px;
            z-index: 2;
            
            position: absolute;
            bottom: -85px;
            max-width: none;
            left: 0;
            right: 0;
            margin: 0 auto;

            @include device-md {
                grid-column: 2/span 5;
            }

            @include device-sm {
                grid-column: 1 / span 7;
                margin-left: 24px;
                margin-right: 24px;
            }

            @include device-lg("min"){
                grid-column: 1 / span 11;
                grid-row: 1;
                padding: 40px 80px;
                border-radius: 48px;

                position: initial;
                bottom: -85px;
                max-width: unset;
                left: 0;
                right: 0;
                margin: unset;
            }

            a{
                display: block;
                text-align: center;
                width: 100%;

                @include device-lg("min"){
                    display: inline-block;
                    width: auto;
                    text-align: center;
                }
            }
            p {
                margin-bottom: 8px;
                color: $primary-color;
            }

            h3 {
                margin-bottom: 24px;
                @include font-size(32, 40, 700, -2);

                @include device-md {
                    @include font-size(26, 34, 700, -2);
                }
            }
        }

        .offers_background {
            grid-column: 1 / span 14;
            grid-row: 1;
            height: 460px;
            border-radius: 40px;
            background: url('../images/home/offer-back-desk.jpg') no-repeat center center;
            background-size: cover;

            @include device-lg("min"){
                grid-column: 9 / span 13;
                height: 400px;
            }

            @include device-md {
                height: 400px;
            }
            
            @include device-sm {
                background-image: url('../images/home/offer-back-mob.jpg');
            }
        }

        .offers_cards {
            position: absolute;
            grid-column: 14 /span 10;
            top: -55px;

            @include device-xl {
                grid-column: 13/span 11;
                top: -60px;
            }

            @include device-lg {
                grid-column: auto;
                top: -120px;
                left: 50%;
                max-width: 480px;
                transform: translateX(-50%);
            }

            @include device-md {
                grid-column: auto;
                top: -100px;
                max-width: 400px;
            }

            @include device-sm {
                top: -70px;
                max-width: 480px;
                width: 84%;
            }

            // .card {
            //     width: 296px;
            //     border-radius: 16px;
            //     padding: 8px;
                
            //     @include device-lg {
            //         width: 250px;
            //         background-color: $white;
            //         padding: 5px;
            //         border-radius: 10px;
            //         position: absolute;
            //         bottom: 0;
            //     }

            //     @include device-md {
            //         width: 225px;
            //     }

            //     .text {
            //         padding: 10px;
            //         @include device-lg("min"){
            //             padding: 16px;
            //         }
            //         .subtitle {
            //             @include font-size(10, 12, 700, 10);
            //             text-transform: uppercase;
            //             color: $primary-color;
            //             margin-bottom: 2px;
            //         }

            //         .--title-s {
            //             font-weight: 700;
            //             color: $black;
            //         }
    
            //         .details {
            //             color: $gray;
            //             margin-top: 2px;
            //             margin-bottom: 8px;
            //             display: inline-flex;
            //             align-items: center;
            //             p{
            //                 letter-spacing: -0.6px;
            //                 @include device-lg("min"){
            //                     letter-spacing: initial;
            //                 }
            //             }
            //             img {
            //                 width: 4px;
            //                 height: 4px;
            //                 margin-left: 5px;
            //                 margin-right: 5px;
            //                 @include device-lg("min"){
            //                     margin-left: 8px;
            //                     margin-right: 8px;
            //                 }
            //             }
    
            //             .model {
            //                 text-transform: uppercase;
            //             }
            //         }
    
            //         .price {
            //             display: flex;
            //             justify-content: space-between;

            //             .--title-s {
            //                 color: $primary-color;
            //             }

            //             img {
            //                 width: 16px;
            //                 height: 16px;
            //                 transform: rotate(180deg);
            //             }
            //         }
                    
            //         .cartel {
            //             background-color: #D2C7EF;
            //             padding: 8px 16px;
            //             border-radius: 8px;
            //             width: 173px;
            //             position: absolute;
            //             bottom: -26px;
            //             text-align: center;
            //         }
                    
            //         .pico {
            //             background: #D2C7EF;
            //             border-radius: 1px;
            //             transform: matrix(-0.71, -0.71, 0.71, -0.71, 0, 0);
            //             position: absolute;
            //             width: 12px;
            //             height: 12px;
            //             left: 107px;
            //             bottom: 4px;
            //         }
            //     }

            // }

            // .first-card {
            //     z-index: 3;
            //     border: 1.5px solid $primary-color;
            // }

            // .second-card {
            //     transform: rotate(30deg) translateX(140px) translateY(-80px);
            //     left: -110px;
            //     @include device-lg("min"){
            //         left: unset;
            //     }
            // }
        }
    }
}