.terms-conditions {
    padding-top: 100px;
    padding-bottom: 120px;

    &_container {
        padding-top: 80px;
        
        .terms-conditions_title {
            text-align: center;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .accordion-container {
            margin-top: 120px;

            h1 {
                margin-bottom: 32px;
            }

            li:first-child {
                border-top: none;
            }

            li {
                border-bottom: 1px solid #DEDEDF;
                padding-top: 24px;
                padding-bottom: 24px;

                .active, .accordion:hover {
                    cursor: pointer;
                }

                .accordion {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .toggle-minus {
                        display: none;
                        transform: rotate(180deg);
                    }
                }
        
                .panel {
                    display: none;
                    overflow: hidden;

                    .--text-m {
                        margin-top: 16px;
                    }
                }
            }
        }

        @include device-lg {
            .accordion-container {
                grid-column: 1 / -1;
            }
        }

        @include device-md {
            .faq_title, .faq_text {
                text-align: left;
            }
            
        .accordion-container {
            margin-top: 40px;
        }
        }
    }
}