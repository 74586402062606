.catalog__grid__filters-desk {
    .filters-form {
        &__field-group {
            margin-bottom: 10px;
    
            &__head {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                margin-bottom: 16px;
    
                legend {
                    @include font-size(20, 24, 700);
                }
            }
    
            .ego-form__field {
                margin-bottom: 20px;
    
                &.--checkbox:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
    
        &__two-columns {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 5px;
    
            @include rwd(1200) {
                display: block;
            }
        }
    
        &__colors-grid {
            display: flex;
            flex-flow: row wrap;
            row-gap: 7px;
            column-gap: 3px;
            margin-bottom: 15px;
    
            .--radio {
                min-width: 30px;
                width: auto;
                margin-bottom: 0;
            }
        }
    
        footer {
            padding: 30px 0;
            background-color: $lighter-gray;
            border-top: 1px solid $mid-gray;
            position: sticky;
            bottom: 0;
            z-index: 7;
    
            .btn-small {
                width: 100%;
            }
        }
    }
}