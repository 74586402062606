///////////////////////
// Breakpoints
$screen-sm-min: 576; // Small tablets and large smartphones (landscape view)
$screen-md-min: 720; // Small tablets (portrait view)
$screen-lg-min: 1024; // Tablets and small desktops
$screen-xl-min: 1440; // Large tablets and desktops

$max_width_wrapper: 1440px;
$timming: .2s;

$white: #FFFFFF;
$lighter-gray: #F7F7F7;
$light-gray: #E8E8E8;
$mid-gray: #DEDEDF;
$gray: #454545;
$dark-gray: #191919;
$black: #000000;

$primary-color: #3A00D5;
$primary-color-soft: #F1ECFF;
$primary-color-darker: #1A0060;
$primary-color-lighter: #5C39E5;
$primary-color-light: #D2C7EF;
$secondary-color: #FFFE5B;

$success-color: #027A48;
$success-color-soft: #D1FADF;
$warning-color: #FEC84B;
$danger-color: #D92D20;
$danger-color-soft: #FEE4E2;