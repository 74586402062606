@charset "UTF-8";
.upper-section {
  position: relative;
  min-height: 1250px;
  background-size: auto 100%;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #F7F7F7;
}
@media (max-width: 1023px) {
  .upper-section {
    min-height: 1400px;
    background-size: auto 70%;
    background-position: bottom center;
  }
  .upper-section::after {
    content: "";
    position: absolute;
    inset: 0 0 auto 0;
    height: 500px;
    background: linear-gradient(0deg, rgba(25, 25, 25, 0) 6%, #191919 22%);
    z-index: 0;
  }
}
@media (max-width: 719px) {
  .upper-section::after {
    height: 690px;
  }
}
@media (max-width: 575px) {
  .upper-section {
    background-size: 900px auto;
    min-height: 1180px;
  }
  .upper-section::after {
    height: 790px;
  }
}
.upper-section img {
  width: 100%;
  height: auto;
}
.upper-section_container {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .upper-section_container {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .upper-section_container {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.upper-section_container_title {
  position: relative;
  z-index: 1;
  grid-column: 5/span 15;
  grid-row: 1;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 24px;
  margin-top: 80px;
}
@media (max-width: 1023px) {
  .upper-section_container_title {
    grid-column: 1/last-col;
  }
}
@media (max-width: 719px) {
  .upper-section_container_title {
    margin-top: 40px;
  }
}
@media (max-width: 575px) {
  .upper-section_container_title {
    font-size: 26px;
  }
}
.upper-section_container_subtitle {
  position: relative;
  z-index: 1;
  grid-column: 5/span 15;
  grid-row: 2;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 40px;
  font-size: clamp(18px, 3vw, 24px);
  line-height: 1.2;
  font-weight: 400;
}
@media (max-width: 1023px) {
  .upper-section_container_subtitle {
    grid-column: 1/last-col;
  }
}
@media (max-width: 719px) {
  .upper-section_container_subtitle {
    margin-top: 40px;
  }
}
@media (max-width: 575px) {
  .upper-section_container_subtitle {
    margin-top: 0px;
  }
}
.upper-section_container .budget-tabs {
  position: relative;
  z-index: 1;
  grid-column: 3/span 19;
  grid-row: 3;
  border-radius: 40px;
  background-color: #FFFFFF;
  overflow: hidden;
}
@media (max-width: 1400px) {
  .upper-section_container .budget-tabs {
    grid-column: 1/last-col;
  }
}
@media (max-width: 1023px) {
  .upper-section_container .budget-tabs {
    grid-column: 1/last-col;
  }
}
@media (max-width: 575px) {
  .upper-section_container .budget-tabs {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
  }
}
.upper-section_container .budget-tabs__header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #DEDEDF;
}
.upper-section_container .budget-tabs__header__btn {
  display: block;
  width: 100%;
  background-color: #FFFFFF;
  border: 0;
  border-bottom: 3px solid;
  border-bottom-color: #FFFFFF;
  padding: 16px 10px 10px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375;
  letter-spacing: normal;
  color: #454545;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.upper-section_container .budget-tabs__header__btn.--active {
  border-bottom-color: #3A00D5;
  color: #3A00D5;
}
.upper-section_container .budget-tabs__tab {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  display: none;
  padding: 40px;
}
@media (min-width: 720px) {
  .upper-section_container .budget-tabs__tab {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .upper-section_container .budget-tabs__tab {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
@media (max-width: 1023px) {
  .upper-section_container .budget-tabs__tab {
    text-align: center;
  }
}
@media (max-width: 575px) {
  .upper-section_container .budget-tabs__tab {
    padding: 40px 20px;
  }
}
.upper-section_container .budget-tabs__tab.--active {
  display: grid;
}
.upper-section_container .budget-tabs__tab.--block {
  pointer-events: none;
}
.upper-section_container .budget-tabs__tab .column1 {
  grid-column: 1/span 14;
  display: grid;
}
@media (max-width: 575px) {
  .upper-section_container .budget-tabs__tab .column1 {
    display: flex;
    flex-direction: column;
  }
}
.upper-section_container .budget-tabs__tab .column1 .row1 {
  grid-row: 1;
}
.upper-section_container .budget-tabs__tab .column1 .row1 .--title-s {
  display: block;
  margin-bottom: 16px;
}
.upper-section_container .budget-tabs__tab .column1 .row1 .inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  column-gap: 10px;
}
@media (max-width: 1023px) {
  .upper-section_container .budget-tabs__tab .column1 .row1 .inputs {
    flex-wrap: wrap;
  }
}
.upper-section_container .budget-tabs__tab .column1 .row1 .ego-form__field {
  margin-bottom: 30px;
}
.upper-section_container .budget-tabs__tab .column1 .row2 {
  grid-row: 2;
  border-top: 1px solid #DEDEDF;
  padding-top: 16px;
}
@media (max-width: 575px) {
  .upper-section_container .budget-tabs__tab .column1 .row2 {
    border-top: unset;
  }
}
@media (max-width: 1023px) {
  .upper-section_container .budget-tabs__tab .column1 .row2 {
    margin-bottom: 25px;
  }
}
.upper-section_container .budget-tabs__tab .column1 .row2-title {
  margin-bottom: 16px;
}
@media (max-width: 575px) {
  .upper-section_container .budget-tabs__tab .column1 .row2-title {
    text-align: center;
  }
}
.upper-section_container .budget-tabs__tab .column1 .row2 .upper-section-swiper {
  width: 100%;
  height: 100px;
  max-width: 630px;
}
@media (max-width: 719px) {
  .upper-section_container .budget-tabs__tab .column1 .row2 .upper-section-swiper {
    max-width: 530px;
    height: 100px;
  }
}
@media (max-width: 575px) {
  .upper-section_container .budget-tabs__tab .column1 .row2 .upper-section-swiper {
    max-width: 300px;
    height: 100px;
  }
}
.upper-section_container .budget-tabs__tab .column1 .row2 .upper-section-swiper .swiper-wrapper .swiper-slide {
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 85px;
  padding: 8px;
  border: 1px solid #DEDEDF;
  border-radius: 16px;
  text-align: center;
  margin-right: 12px !important;
  cursor: pointer;
  transition: border 300ms ease-in-out;
}
.upper-section_container .budget-tabs__tab .column1 .row2 .upper-section-swiper .swiper-wrapper .swiper-slide:hover, .upper-section_container .budget-tabs__tab .column1 .row2 .upper-section-swiper .swiper-wrapper .swiper-slide.--active {
  border: 1px solid #5A3FEE;
}
.upper-section_container .budget-tabs__tab .column1 .row2 .upper-section-swiper .swiper-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}
.upper-section_container .budget-tabs__tab .column1 .row2 .upper-section-swiper .swiper-pagination {
  bottom: 0px;
}
.upper-section_container .budget-tabs__tab .column1 .row2 .upper-section-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3A00D5;
}
.upper-section_container .budget-tabs__tab .column2 {
  grid-column: 16/-1;
  text-align: center;
}
.upper-section_container .budget-tabs__tab .column2 button {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1023px) {
  .upper-section_container .budget-tabs__tab .column2 {
    grid-column: 1/span 14;
  }
}
@media (max-width: 575px) {
  .upper-section_container .budget-tabs__tab .column2 {
    width: 100%;
  }
}
.upper-section_container .budget-tabs__tab .column2 .--title-m {
  display: block;
  margin-bottom: 8px;
}
.upper-section_container .budget-tabs__tab .column2 h1 {
  margin-bottom: 49px;
}
.upper-section_container .budget-tabs__tab .column2 a {
  display: block;
}
@media (max-width: 1023px) {
  .upper-section_container .budget-tabs__tab .column2 h1 {
    margin-bottom: 16px;
  }
}
@media (min-width: 1024px) {
  .upper-section_container .budget-tabs #homeBudgetForm .ego-form__field {
    max-width: 50%;
  }
}

.filters {
  background-repeat: no-repeat;
  background-size: cover;
}
.filters_container {
  padding-top: 32px;
  margin-bottom: -33px;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .filters_container {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .filters_container {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.filters_container h3 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -0.02%em;
  text-align: center;
  margin-bottom: 24px;
  color: #FFF;
  grid-column: 2/span 21;
}
.filters_container h3 span {
  color: #B09BEA;
}
.filters_container__filters {
  background-color: #FFF;
  border-radius: 16px;
  padding: 32px 40px 8px 40px;
  grid-column: 2/span 21;
}
.filters_container__logo {
  grid-column: 1/-1;
  grid-row: 3;
  max-width: 280px;
  margin-top: 24px;
  justify-self: center;
}
.filters__form {
  display: flex;
  gap: 25px;
}
.filters__form button {
  height: max-content;
}
.filters__form .ego-form__field {
  margin-bottom: 24px;
}
@media (max-width: 1024px) {
  .filters_container h3, .filters_container__filters {
    grid-column: 1/-1;
  }
}
@media (max-width: 719px) {
  .filters_container {
    margin-bottom: -20px;
  }
  .filters_container__filters {
    padding: 24px 20px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .filters_container h3 {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.3076923077;
    letter-spacing: -0.02%em;
    margin-bottom: 32px;
  }
  .filters__form {
    flex-direction: column;
    gap: 0;
  }
}

.featured {
  background-color: #F7F7F7;
  padding: 20px 0 100px 0;
  overflow: hidden;
}
@media (max-width: 575px) {
  .featured {
    padding: 60px 0;
  }
}
.featured__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .featured__header {
    margin-bottom: 46px;
  }
}
.featured__header h2 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: normal;
  color: #3A00D5;
}
.featured__header h3 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: normal;
}
@media (max-width: 575px) {
  .featured__header h3 {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.3076923077;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .featured #featured-section-swiper {
    overflow: visible;
  }
}
.featured #featured-section-swiper .swiper-wrapper {
  padding-bottom: 60px;
}
.featured #featured-section-swiper .swiper-wrapper .swiper-slide {
  height: auto;
}
.featured #featured-section-swiper .swiper-wrapper .swiper-slide a {
  margin: auto;
  display: block;
  height: 100%;
}
@media (max-width: 1023px) {
  .featured .swiper-controls {
    display: none;
  }
}
.featured .swiper-controls .swiper-button-prev {
  left: 10px;
}
.featured .swiper-controls .swiper-button-next {
  right: 10px;
}
.featured .swiper-controls .swiper-button-prev, .featured .swiper-controls .swiper-button-next {
  background-color: #FFFFFF;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s ease-in-out;
}
.featured .swiper-controls .swiper-button-prev svg path, .featured .swiper-controls .swiper-button-next svg path {
  transition: stroke 0.1s ease-in-out;
}
.featured .swiper-controls .swiper-button-prev:after, .featured .swiper-controls .swiper-button-next:after {
  content: "";
}
.featured .swiper-controls .swiper-button-prev:hover, .featured .swiper-controls .swiper-button-next:hover {
  background-color: #3A00D5;
}
.featured .swiper-controls .swiper-button-prev:hover svg path, .featured .swiper-controls .swiper-button-next:hover svg path {
  stroke: #FFFFFF;
}
.featured .swiper-pagination .swiper-pagination-bullet {
  width: 13px;
  height: 10px;
  display: inline-block;
  background: #454545;
  opacity: 0.2;
  -webkit-border-radius: 20px;
  margin: 0 10px !important;
  border-radius: 20px;
  -webkit-transition: opacity 0.2s, background-color 0.5s, width 0.5s;
  -o-transition: opacity 0.2s, background-color 0.5s, width 0.5s;
  transition: opacity 0.2s, background-color 0.5s, width 0.5s;
}
.featured .swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #454545;
  width: 40px;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.most-seen {
  background: url("../images/home/most-seen-bg.png");
  background-size: cover;
  padding: 100px 0;
}
@media (max-width: 575px) {
  .most-seen {
    padding: 60px 0;
  }
}
.most-seen__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .most-seen__header {
    margin-bottom: 46px;
  }
}
.most-seen__header h2 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: normal;
  color: #3A00D5;
}
.most-seen__header h3 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: normal;
}
@media (max-width: 575px) {
  .most-seen__header h3 {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.3076923077;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .most-seen #most-seen-section-swiper {
    overflow: visible;
  }
}
.most-seen #most-seen-section-swiper .swiper-wrapper {
  padding-bottom: 60px;
}
.most-seen #most-seen-section-swiper .swiper-wrapper .swiper-slide {
  height: auto;
}
.most-seen #most-seen-section-swiper .swiper-wrapper .swiper-slide a {
  margin: auto;
  display: block;
  height: 100%;
}
@media (max-width: 1023px) {
  .most-seen .swiper-controls {
    display: none;
  }
}
.most-seen .swiper-controls .swiper-button-prev {
  left: 10px;
}
.most-seen .swiper-controls .swiper-button-next {
  right: 10px;
}
.most-seen .swiper-controls .swiper-button-prev, .most-seen .swiper-controls .swiper-button-next {
  background-color: #FFFFFF;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s ease-in-out;
}
.most-seen .swiper-controls .swiper-button-prev svg path, .most-seen .swiper-controls .swiper-button-next svg path {
  transition: stroke 0.1s ease-in-out;
}
.most-seen .swiper-controls .swiper-button-prev:after, .most-seen .swiper-controls .swiper-button-next:after {
  content: "";
}
.most-seen .swiper-controls .swiper-button-prev:hover, .most-seen .swiper-controls .swiper-button-next:hover {
  background-color: #3A00D5;
}
.most-seen .swiper-controls .swiper-button-prev:hover svg path, .most-seen .swiper-controls .swiper-button-next:hover svg path {
  stroke: #FFFFFF;
}
.most-seen .swiper-pagination .swiper-pagination-bullet {
  width: 13px;
  height: 10px;
  display: inline-block;
  background: #454545;
  opacity: 0.2;
  -webkit-border-radius: 20px;
  margin: 0 10px !important;
  border-radius: 20px;
  -webkit-transition: opacity 0.2s, background-color 0.5s, width 0.5s;
  -o-transition: opacity 0.2s, background-color 0.5s, width 0.5s;
  transition: opacity 0.2s, background-color 0.5s, width 0.5s;
}
.most-seen .swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #454545;
  width: 40px;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.certification {
  background-color: #F7F7F7;
}
.certification_text {
  text-align: center;
  max-width: 630px;
  margin: auto;
}
.certification_text .subtitle {
  color: #3A00D5;
  margin-bottom: 8px;
}
.certification_text h2 {
  margin-bottom: 16px;
}
.certification_text .text {
  margin-bottom: 40px;
}
.certification_boxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 2em 1em;
  justify-items: center;
}
.certification_boxes .box {
  text-align: center;
}
.certification_boxes .box img {
  width: 140px;
  height: 140px;
}
.certification_boxes .box .title {
  margin-top: 24px;
  margin-bottom: 16px;
}
.certification_button {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.offers {
  position: relative;
  background-color: #F7F7F7;
  padding-top: 120px;
  padding-bottom: 100px;
}
@media (max-width: 1023px) {
  .offers {
    padding-top: 160px;
  }
}
@media (max-width: 575px) {
  .offers {
    padding-top: 120px;
    padding-bottom: 150px;
  }
}
.offers .wrapper {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .offers .wrapper {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .offers .wrapper {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.offers .wrapper img {
  width: 100%;
}
.offers .wrapper .offers_text {
  grid-column: 2/span 9;
  grid-row: 1;
  background: #FFFFFF;
  padding: 24px;
  border-radius: 15px;
  z-index: 2;
  position: absolute;
  bottom: -85px;
  max-width: none;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media (max-width: 719px) {
  .offers .wrapper .offers_text {
    grid-column: 2/span 5;
  }
}
@media (max-width: 575px) {
  .offers .wrapper .offers_text {
    grid-column: 1/span 7;
    margin-left: 24px;
    margin-right: 24px;
  }
}
@media (min-width: 1024px) {
  .offers .wrapper .offers_text {
    grid-column: 1/span 11;
    grid-row: 1;
    padding: 40px 80px;
    border-radius: 48px;
    position: initial;
    bottom: -85px;
    max-width: unset;
    left: 0;
    right: 0;
    margin: unset;
  }
}
.offers .wrapper .offers_text a {
  display: block;
  text-align: center;
  width: 100%;
}
@media (min-width: 1024px) {
  .offers .wrapper .offers_text a {
    display: inline-block;
    width: auto;
    text-align: center;
  }
}
.offers .wrapper .offers_text p {
  margin-bottom: 8px;
  color: #3A00D5;
}
.offers .wrapper .offers_text h3 {
  margin-bottom: 24px;
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -0.02em;
}
@media (max-width: 719px) {
  .offers .wrapper .offers_text h3 {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.3076923077;
    letter-spacing: -0.02em;
  }
}
.offers .wrapper .offers_background {
  grid-column: 1/span 14;
  grid-row: 1;
  height: 460px;
  border-radius: 40px;
  background: url("../images/home/offer-back-desk.jpg") no-repeat center center;
  background-size: cover;
}
@media (min-width: 1024px) {
  .offers .wrapper .offers_background {
    grid-column: 9/span 13;
    height: 400px;
  }
}
@media (max-width: 719px) {
  .offers .wrapper .offers_background {
    height: 400px;
  }
}
@media (max-width: 575px) {
  .offers .wrapper .offers_background {
    background-image: url("../images/home/offer-back-mob.jpg");
  }
}
.offers .wrapper .offers_cards {
  position: absolute;
  grid-column: 14/span 10;
  top: -55px;
}
@media (max-width: 1439px) {
  .offers .wrapper .offers_cards {
    grid-column: 13/span 11;
    top: -60px;
  }
}
@media (max-width: 1023px) {
  .offers .wrapper .offers_cards {
    grid-column: auto;
    top: -120px;
    left: 50%;
    max-width: 480px;
    transform: translateX(-50%);
  }
}
@media (max-width: 719px) {
  .offers .wrapper .offers_cards {
    grid-column: auto;
    top: -100px;
    max-width: 400px;
  }
}
@media (max-width: 575px) {
  .offers .wrapper .offers_cards {
    top: -70px;
    max-width: 480px;
    width: 84%;
  }
}

.how-it-works {
  background-size: cover;
  background-position-x: center;
  background-color: hsl(0deg, 0%, 97%);
}
.how-it-works .wrapper {
  position: relative;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .how-it-works .wrapper {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .how-it-works .wrapper {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
@media (max-width: 1023px) {
  .how-it-works .wrapper {
    display: block;
  }
}
.how-it-works_head {
  grid-column: 2/span 20;
  margin-bottom: 40px;
}
.how-it-works_head h3 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -0.02em;
}
@media (max-width: 719px) {
  .how-it-works_head h3 {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.3076923077;
    letter-spacing: -0.02em;
  }
}
.how-it-works_content {
  grid-column: 1/span 22;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  justify-items: end;
  row-gap: 50px;
  padding: 63px 0;
  border-radius: 80px;
  background: linear-gradient(270deg, rgba(25, 25, 25, 0.03) 16.92%, rgba(25, 25, 25, 0.88) 94.33%), url("../images/home/how-it-works/asphalt.jpg") no-repeat center center;
  background-size: cover;
}
@media (min-width: 720px) {
  .how-it-works_content {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .how-it-works_content {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.how-it-works_content::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
  background: url("../images/home/how-it-works/car2.png") no-repeat bottom right;
  background-size: 63%;
  border-radius: 0 0 80px 80px;
}
@media (max-width: 1200px) {
  .how-it-works_content::after {
    background-size: 57%;
  }
}
@media (max-width: 1180px) {
  .how-it-works_content {
    padding-bottom: 120px;
  }
}
@media (max-width: 1023px) {
  .how-it-works_content {
    display: block;
    padding: 80px 50px;
    border-radius: 40px;
  }
  .how-it-works_content::after {
    display: none;
  }
}
@media (max-width: 575px) {
  .how-it-works_content {
    padding: 50px 16px;
  }
}
.how-it-works_content .how-it-works_list {
  position: relative;
  z-index: 2;
  grid-column: 2/span 18;
  display: grid;
  grid-template-columns: 390px 390px;
  width: 100%;
  max-width: 890px;
  justify-content: space-between;
  gap: 10%;
}
@media (max-width: 1180px) {
  .how-it-works_content .how-it-works_list {
    grid-template-columns: 60%;
    gap: 16px;
  }
}
@media (max-width: 1023px) {
  .how-it-works_content .how-it-works_list {
    grid-template-columns: 100%;
  }
}
@media (max-width: 575px) {
  .how-it-works_content .how-it-works_list {
    width: 100%;
  }
}
.how-it-works_content .how-it-works_list .how-it-works_item:not(:last-child) {
  margin-bottom: 24px;
}
.how-it-works_content .how-it-works_list .how-it-works_item:last-child {
  padding-right: 8px;
}
.how-it-works_content .how-it-works_list .how-it-works_item .how-it-works_table {
  color: #FFFFFF;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 8px;
}
.how-it-works_content .how-it-works_list .how-it-works_item .how-it-works_table .table-head {
  color: #000000;
  background-color: #DEDEDF;
  border-radius: 10px;
  padding: 5px 22px;
  display: grid;
  grid-template-columns: 190px 120px;
  gap: 16px;
  align-items: center;
  font-weight: 700;
}
.how-it-works_content .how-it-works_list .how-it-works_item .how-it-works_table .table-content {
  display: grid;
  grid-template-columns: 190px 120px;
  gap: 16px;
  align-items: center;
  padding: 8px 24px;
  border-bottom: 1px solid #FFFFFF;
}
.how-it-works_content .how-it-works_list .how-it-works_item .how-it-works_table .table-content:last-child {
  border-bottom: none;
}
@media (max-width: 719px) {
  .how-it-works_content .how-it-works_list .how-it-works_item .how-it-works_table .table-content, .how-it-works_content .how-it-works_list .how-it-works_item .how-it-works_table .table-head {
    grid-template-columns: 50% 35%;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
}
.how-it-works_content .how-it-works_button {
  position: relative;
  z-index: 2;
  grid-column: 12/span 11;
  align-self: end;
}
@media (max-width: 1023px) {
  .how-it-works_content .how-it-works_button {
    margin-top: 60px;
  }
  .how-it-works_content .how-it-works_button .btn-large {
    margin: auto;
    max-width: 300px;
  }
}
@media (max-width: 575px) {
  .how-it-works_content .how-it-works_button {
    margin-top: 30px;
  }
  .how-it-works_content .how-it-works_button .btn-large {
    max-width: none;
  }
}
.how-it-works_content .how-it-works_head p {
  color: #3A00D5;
  margin-bottom: 8px;
}
.how-it-works_content .how-it-works_head h2 {
  margin-bottom: 40px;
}
.how-it-works_content .title {
  display: flex;
  border-bottom: 1px solid #DEDEDF;
  padding-bottom: 8px;
  margin-bottom: 8px;
  color: #FFFFFF;
}
.how-it-works_content .title img {
  transform: rotate(180deg);
  margin-right: 8px;
}
.how-it-works_content .text {
  color: #FFFFFF;
}
.how-it-works_content .--title-s {
  font-weight: 700;
}

.operations {
  background-color: #F5F5F5;
  padding-bottom: 40px;
}
.operations .wrapper {
  padding-top: 40px;
}
.operations .wrapper .operations_text {
  margin-bottom: 24px;
}
.operations .wrapper .operations_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
@media (max-width: 1023px) {
  .operations .wrapper .operations_container {
    display: flex;
    flex-direction: column;
  }
}

.video {
  background-color: #F7F7F7;
}
.video__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
  max-width: 815px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 575px) {
  .video__header {
    margin-bottom: 46px;
  }
}
.video__header h2 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: normal;
  color: #3A00D5;
  text-transform: uppercase;
}
.video__header h3 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: normal;
}
@media (max-width: 575px) {
  .video__header h3 {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.3076923077;
    letter-spacing: normal;
  }
}
.video__header p {
  margin-top: 8px;
  max-width: 608px;
  color: #454545;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: normal;
}
.video__big-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-bottom: 40px;
}
@media (max-width: 719px) {
  .video__big-container {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
.video__big-container:not(:has(.child-2)) {
  grid-template-columns: 1fr;
}
.video__big-container:not(:has(.child-2)) .video__container {
  width: 50%;
  justify-self: center;
}
.video__container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.video__container__element {
  height: 320px;
  width: 100%;
  position: relative;
  border-radius: 40px;
  overflow: hidden;
}
.video__container__element:hover {
  cursor: pointer;
}
.video__container__element__video {
  height: 100%;
  width: 100%;
}
.video__container__element__thumbnail {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
.video__container__element__thumbnail .gradient {
  display: none;
}
@media (max-width: 719px) {
  .video__container__element__thumbnail .gradient {
    display: block;
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.video__container__element .video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.video__container__element .video-thumbnail {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.video__container__element__title {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1666666667;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  width: 80%;
  z-index: 2;
}
.video__container__element__tag {
  position: absolute;
  top: 32px;
  left: 32px;
  background-color: #B09BEA;
  color: #1A0060;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 2px 12px;
  border-radius: 19px;
  z-index: 2;
}
.video__container__cta {
  padding: 8px 24px;
  background-color: #3A00D5;
  color: #fff;
  border-radius: 48px;
  width: max-content;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375;
  letter-spacing: normal;
  align-self: center;
  display: flex;
  margin-top: 24px;
}
.video__container__cta svg {
  margin-left: 16px;
}
.video__container__cta p {
  margin-bottom: 2px;
}

.banner {
  padding: 0;
}
.banner_img {
  width: 100vw;
}

.banner-carousel {
  padding-top: 0;
  background-color: #f7f7f7;
}
.banner-carousel__swiper {
  padding-bottom: 40px !important;
}
.banner-carousel__swiper .swiper-pagination {
  bottom: 0;
}
.banner-carousel__swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: #454545;
  width: 40px;
  height: 10px;
  border-radius: 5px;
}
.banner-carousel__swiper .swiper-button-next {
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
  color: #fff;
  transform: rotate(180deg);
}
.banner-carousel__swiper .swiper-button-next img {
  position: absolute;
  left: 12px;
}
.banner-carousel__swiper .swiper-button-next:after {
  display: none;
}
.banner-carousel__swiper .swiper-button-prev {
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
  color: #fff;
}
.banner-carousel__swiper .swiper-button-prev img {
  position: absolute;
  left: 12px;
}
.banner-carousel__swiper .swiper-button-prev:after {
  display: none;
}
.banner-carousel__swiper .swiper-slide {
  max-height: 240px;
  width: 100% !important;
  display: flex;
  justify-content: center;
}
.banner-carousel__swiper .swiper-slide a {
  border-radius: 16px;
  overflow: hidden;
}
.banner-carousel__swiper .swiper-slide a .img-mobile {
  display: none;
}
@media (max-width: 1023px) {
  .banner-carousel__swiper .swiper-slide a .img-desktop {
    display: none;
  }
  .banner-carousel__swiper .swiper-slide a .img-mobile {
    display: block;
  }
}

.registrate {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  min-height: 100vh;
  align-content: center;
}
@media (min-width: 720px) {
  .registrate {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .registrate {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.registrate_text {
  text-align: center;
  padding-top: 92px;
  margin-left: auto;
  margin-right: auto;
  grid-column: 7/span 11;
  grid-row: 1;
}
.registrate_text h1 {
  margin-bottom: 12px;
}
.registrate_text p {
  margin-bottom: 24px;
  color: #454545;
}
@media (max-width: 1023px) {
  .registrate_text {
    grid-column: 1/-1;
  }
}
.registrate .contactForm {
  grid-column: 5/span 15;
  grid-row: 2;
  display: grid;
  column-gap: 16px;
  text-align: center;
  grid-template-columns: repeat(2, 1fr);
}
.registrate .contactForm .--izq {
  grid-column: 1;
}
.registrate .contactForm .--der {
  grid-column: 2;
}
.registrate .contactForm .full-width {
  grid-column: 1/span 2 !important;
}
.registrate .contactForm .create {
  margin-top: 8px;
  margin-bottom: 16px;
  color: #454545;
}
.registrate .contactForm .check {
  margin-top: 8px;
  color: #454545;
  display: flex;
  align-items: center;
}
.registrate .contactForm .check label {
  margin-left: 10px;
}
.registrate .contactForm .check label span a {
  pointer-events: auto;
  color: #5C39E5;
}
@media (max-width: 575px) {
  .registrate .contactForm .check {
    text-align: left;
    display: flex;
    align-items: flex-start;
  }
  .registrate .contactForm .check span {
    margin-left: 8px;
  }
}
.registrate .contactForm .registrate-check-label a {
  pointer-events: auto;
  color: #5C39E5;
}
.registrate .contactForm .btn button {
  width: 400px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 575px) {
  .registrate .contactForm .btn button {
    width: 100%;
  }
}
.registrate .contactForm .link {
  margin-top: 16px;
}
@media (max-width: 1023px) {
  .registrate .contactForm {
    grid-column: 1/-1;
  }
}
@media (max-width: 575px) {
  .registrate .contactForm div:nth-child(2n+1) {
    grid-column: 1/span 2;
  }
  .registrate .contactForm div:nth-child(2n) {
    grid-column: 1/span 2;
  }
}
.registrate .back-to-profile {
  display: flex;
  grid-column: 1/-1;
  grid-row: 1;
  align-self: start;
  margin-top: 40px;
}
.registrate .back-to-profile a {
  color: #3A00D5;
  margin-left: 12px;
  text-decoration: underline;
}
@media (max-width: 1023px) {
  .registrate .back-to-profile {
    grid-row: 1;
  }
}
.registrate .warning-mail {
  background-color: #F1ECFF;
  margin-bottom: 40px;
  display: flex;
  padding: 8px 18px;
  border-radius: 8px;
  align-items: center;
}
.registrate .warning-mail img {
  width: 16px;
  height: 16px;
  margin-right: 18px;
}
.registrate .warning-mail p {
  color: #3A00D5;
  line-height: 17px;
}
@media (max-width: 719px) {
  .registrate .warning-mail img {
    margin-right: 8px;
  }
  .registrate .warning-mail p {
    font-size: 12px;
  }
}
.registrate .contactForm1 {
  grid-row: 2;
}
.registrate .contactForm1 .btn-large {
  margin-top: 0 !important;
  margin-bottom: 40px;
}
.registrate .contactForm2 {
  grid-row: 3;
}
.registrate .contactForm2 .btn-large {
  margin-top: 0 !important;
  margin-bottom: 40px;
}
.registrate .contactForm3 {
  grid-row: 4;
}
.registrate .contactForm3 .btn-large {
  margin-top: 0 !important;
  margin-bottom: 40px;
}

.car-on-the-right {
  position: relative;
  padding-top: 100px;
  overflow: hidden;
}
.car-on-the-right .container {
  min-height: calc(100vh - 100px);
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  align-content: center;
}
@media (min-width: 720px) {
  .car-on-the-right .container {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .car-on-the-right .container {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.car-on-the-right .container .content {
  grid-column: 1/span 7;
}
.car-on-the-right .container .content .h1-mb-32 {
  margin-bottom: 32px;
}
.car-on-the-right .container .content .h1-mb-12 {
  margin-bottom: 12px;
}
.car-on-the-right .container .content h1 {
  text-align: left;
  display: flex;
  flex-direction: column;
}
.car-on-the-right .container .content .primary-btn {
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.car-on-the-right .container .content a {
  font-weight: 700;
  text-align: center;
  display: block;
}
.car-on-the-right .container .content p {
  color: #454545;
  margin-bottom: 32px;
}
.car-on-the-right .container .content p a {
  display: inline;
}
.car-on-the-right .login-img-d {
  position: absolute;
  right: 0;
  left: 50%;
  top: 84px;
  height: calc(100% - 84px);
  border-radius: 80px 0px 0px 80px;
  background-size: contain;
  background-position: left center !important;
  background-repeat: no-repeat;
}
.car-on-the-right .login-img-m {
  display: none;
}
@media (max-width: 1250px) {
  .car-on-the-right .container .content {
    grid-column: 1/span 8;
  }
  .car-on-the-right .login-img-d {
    left: 50%;
  }
}
@media (max-width: 1023px) {
  .car-on-the-right .container .content {
    grid-column: 1/span 5;
  }
  .car-on-the-right .login-img-d {
    left: 55%;
  }
}
@media (max-width: 719px) {
  .car-on-the-right {
    padding-bottom: 0;
    text-align: center;
    display: block;
  }
  .car-on-the-right .container {
    min-height: auto;
    margin: auto;
  }
  .car-on-the-right .container .content {
    grid-column: 1/-1;
  }
  .car-on-the-right .container .content h1, .car-on-the-right .container .content p {
    text-align: center;
  }
  .car-on-the-right .container .content .text-align-left-mobile {
    text-align: left;
  }
  .car-on-the-right .container .content .primary-btn, .car-on-the-right .container .content .general-error {
    max-width: 400px;
  }
  .car-on-the-right .container .content .primary-btn {
    margin-bottom: 102px;
  }
  .car-on-the-right .login-img-d {
    display: none;
  }
  .car-on-the-right .login-img-m {
    display: block;
    width: 100%;
    height: 800px;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.login .container .content .primary-link {
  display: block;
  text-align: center;
}
.login .container .content .primary-btn {
  margin: 24px auto 32px auto;
}
.login .container .content .--link-s {
  color: #3A00D5;
  margin-left: 5px;
}
.login .container .content .p-and-a {
  display: flex;
  justify-content: center;
}
@media (max-width: 719px) {
  .login .container .content h1 {
    display: block;
  }
  .login .container .content .--link-s {
    color: #E30018;
  }
}

.social-login {
  margin-bottom: 32px;
}
.social-login__separator {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 32px;
}
.social-login__separator p {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  background-color: white;
  padding: 0 8px;
  display: inline-block;
  margin-bottom: 0 !important;
  z-index: 1;
}
.social-login__separator::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: #E8E8E8;
}
.social-login__buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.login-post-signup .container {
  padding-top: 40px;
}
.login-post-signup .container .content .primary-link {
  display: block;
  text-align: center;
}
.login-post-signup .container .content .primary-btn {
  margin: 28px auto 32px auto;
}
.login-post-signup .container .content .--link-s {
  color: #3A00D5;
}
@media (max-width: 719px) {
  .login-post-signup .container .content h1 {
    display: block;
  }
  .login-post-signup .container .content .--link-s {
    color: #E30018;
  }
}

.alert {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: max-content;
  border-radius: 8px;
  margin: 0 auto 24px;
  text-align: left;
  background-color: #E8E8E8;
  color: #454545;
  padding: 14px 17px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  letter-spacing: normal;
  transition: all 0.2s ease-in-out;
}
.alert.--small {
  padding: 5px 10px;
}
.alert.--info, .alert.--warning {
  background-color: #D2C7EF;
  color: #3A00D5;
}
.alert.--success {
  background-color: #D1FADF;
  color: #027A48;
}
.alert.--danger {
  background-color: #FEE4E2;
  color: #D92D20;
}
.alert.--dismissible {
  position: relative;
  padding-right: 50px;
}
.alert.--dismissible:after {
  content: "×";
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  color: currentColor;
  padding: 3px 3px 6px;
  font-size: 25px;
  font-size: 1.5625rem;
  font-weight: 400;
  line-height: 0.56;
  letter-spacing: normal;
}
.alert.--close {
  opacity: 0;
  transform: translateY(-50%);
}
.alert.--hide {
  display: none;
}
.alert.--no-margin {
  margin: 0;
}
.alert.--floating {
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: fit-content;
  margin: auto 0 24px;
}
.alert .icon {
  height: 1rem;
}

.faq {
  background-color: #F5F5F5;
  padding-top: 100px;
  padding-bottom: 120px;
}
.faq_container {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  place-content: center;
  padding-top: 80px;
}
@media (min-width: 720px) {
  .faq_container {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .faq_container {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.faq_container .faq_title, .faq_container .faq_text {
  text-align: center;
  grid-column: 1/-1;
  margin-top: 12px;
  margin-bottom: 12px;
}
.faq_container .faq_text {
  color: #454545;
}
.faq_container .accordion-container {
  grid-column: 5/span 15;
  margin-top: 120px;
}
.faq_container .accordion-container h1 {
  margin-bottom: 32px;
}
.faq_container .accordion-container li:first-child {
  border-top: 1px solid #8E6EE3;
}
.faq_container .accordion-container li {
  border-bottom: 1px solid #8E6EE3;
  padding-top: 24px;
  padding-bottom: 24px;
}
.faq_container .accordion-container li .description {
  color: #454545;
  margin-right: 48px;
  margin-top: 8px;
}
.faq_container .accordion-container li .active, .faq_container .accordion-container li .accordion:hover {
  cursor: pointer;
}
.faq_container .accordion-container li .accordion {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.faq_container .accordion-container li .accordion .toggle-minus {
  display: none;
}
.faq_container .accordion-container li .panel {
  display: none;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .faq_container .accordion-container {
    grid-column: 1/-1;
  }
}
@media (max-width: 719px) {
  .faq_container .faq_title, .faq_container .faq_text {
    text-align: left;
  }
  .faq_container .accordion-container {
    margin-top: 40px;
  }
}

.content-center {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  min-height: 100vh;
  align-content: center;
}
@media (min-width: 720px) {
  .content-center {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .content-center {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.content-center_text {
  text-align: center;
  padding-top: 92px;
  margin-left: auto;
  margin-right: auto;
  grid-column: 7/span 11;
  grid-row: 1;
}
.content-center_text h1 {
  margin-bottom: 12px;
}
.content-center_text p {
  margin-bottom: 24px;
  color: #454545;
}
@media (max-width: 1023px) {
  .content-center_text {
    grid-column: 1/-1;
  }
}
.content-center .contactForm {
  grid-column: 7/span 11;
  grid-row: 2;
  display: grid;
  text-align: center;
}
.content-center .contactForm .ego-form__field {
  max-width: 400px;
  min-width: 343px;
  margin-left: auto;
  margin-right: auto;
}
.content-center .contactForm .btn button {
  max-width: 400px;
  min-width: 343px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.content-center .contactForm .link {
  margin-top: 16px;
}
@media (max-width: 1023px) {
  .content-center .contactForm {
    grid-column: 1/-1;
  }
}
.content-center_buttons {
  grid-column: 9/span 7;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
}
.content-center_buttons p {
  text-align: center;
}
.content-center_buttons p span strong {
  color: #E30018;
}
.content-center_buttons a {
  margin-top: 16px;
  min-width: 343px;
  max-width: 400px;
}
@media (max-width: 1023px) {
  .content-center_buttons {
    grid-column: 1/-1;
  }
  .content-center_buttons p {
    display: flex;
    flex-direction: column;
  }
}

.post-car {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  min-height: calc(100vh - 70px);
  align-content: center;
}
@media (min-width: 720px) {
  .post-car {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .post-car {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.post-car_text {
  grid-column: 7/span 11;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.post-car_text p {
  color: #454545;
  margin-top: 24px;
}
.post-car_text p.mt-12 {
  margin-top: 12px;
}
.post-car_text_disclaimer {
  max-width: 400px;
  margin: 46px auto;
}
.post-car_text_disclaimer .disclaimer-title {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5555555556;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 0;
  color: #000000;
}
.post-car_text_disclaimer .disclaimer-img {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.post-car_text_disclaimer .disclaimer-img svg {
  max-width: 80px;
  max-height: 80px;
  min-height: 80px;
  min-width: 80px;
}
.post-car_text_disclaimer .disclaimer-img div p {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
  color: #454545;
  margin-top: 0;
  margin-left: 24px;
}
.post-car_text_disclaimer .disclaimer-img div p:first-of-type {
  margin-bottom: 8px;
}
.post-car_form {
  grid-column: 7/span 11;
  text-align: center;
  margin-top: 80px;
}
.post-car_form .ego-form__field {
  max-width: 400px;
  min-width: 343px;
  margin-left: auto;
  margin-right: auto;
}
.post-car_form .post-car_form_error .ego-form__field__error.--active {
  padding: 46px 72px;
  position: relative;
  top: 0;
  background-color: #F7F7F7;
  margin-top: 48px;
  border-radius: 16px;
  color: #191919;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: 0em;
  text-align: center;
  background-image: none !important;
  justify-content: center;
}
.post-car_form .post-car_form_error p {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  letter-spacing: 0em;
  width: 300px;
  margin: 24px auto;
  font-family: "ToyotaType", sans-serif;
}
.post-car_model {
  grid-column: 7/span 11;
  background-color: #F7F7F7;
  padding: 24px;
  max-width: 400px;
  min-width: 343px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
  margin-bottom: 24px;
}
.post-car_model .--subtitle {
  color: #5C39E5;
}
.post-car_btn .btn-large {
  max-width: 400px;
  min-width: 343px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.post-car_btn .primary-link {
  margin-top: 16px;
}
.post-car_btn .loading-react {
  max-width: 400px;
  min-width: 343px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  column-gap: 8px;
}
.post-car_btn .loading-react svg {
  animation: spin 2s infinite linear;
}
.post-car_btn .loading-react-fifth {
  align-items: center;
  justify-content: center;
  display: flex;
  column-gap: 8px;
  width: 194.5px !important;
  min-width: unset !important;
}
.post-car_btn .loading-react-fifth svg {
  animation: spin 2s infinite linear;
}
@media (max-width: 575px) {
  .post-car_btn .loading-react-fifth {
    width: 100%;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.post-car_link {
  display: block;
  margin-top: 16px;
}
.post-car_box1 {
  display: flex;
  border: 2px solid #E8E8E8;
  border-radius: 16px;
  text-align: left;
  margin-bottom: 8px;
  overflow: hidden;
}
.post-car_box1_left {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.post-car_box1_left div {
  margin-bottom: 0;
}
.post-car_box1_left p {
  margin-bottom: 24px;
}
.post-car_box1_left .--text-xs {
  margin-bottom: 0px;
  margin-top: 40px;
  padding-top: 8px;
  border-top: 1px solid #EEEEEF;
  font-style: italic;
  color: #454545;
}
.post-car_box1_left .textlink {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0;
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
  z-index: 2;
}
.post-car_box1_left .commission-detail {
  padding: 12px 0 6px;
  font-size: 12px;
  display: none;
  gap: 8px;
}
.post-car_box1_left .commission-detail p {
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.post-car_box1_left .commission-detail__final-price {
  font-size: 14px;
  font-weight: 800;
}
.post-car_box1_left .commission-detail.active {
  display: flex;
  flex-direction: column;
}
.post-car_box1_right {
  background-color: #F7F7F7;
  color: #3A00D5;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.post-car_box1_right .tcuv-container {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.post-car_box1_right .tcuv-container .tcuv {
  height: 24px;
  margin-right: 8px;
}
.post-car_box1_right .tcuv-container .info {
  width: 8px;
  margin-left: 2px;
}
.post-car_box1_right .tcuv-tooltip {
  display: none;
  width: 326px;
  position: absolute;
  top: 10px;
  left: 14px;
}
.post-car_box1_right .tcuv-container:hover + .tcuv-tooltip {
  display: block;
}
.post-car_box1_right .loading {
  animation: spin 2s infinite linear;
  height: 40px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.post-car_box2 {
  border: 2px solid #E8E8E8;
  border-radius: 16px;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 41px 32px;
}
.post-car_box2 .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  overflow: auto;
}
.post-car_box2 .container .text-dropzone {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  color: #454545;
  margin-top: 33px;
}
.post-car_box2 .container .dropzone {
  grid-column: 2;
  grid-row: 1;
  width: max-content;
  align-self: center;
  justify-self: center;
}
.post-car_box2 .container aside {
  grid-column: 1/-1;
  grid-row: 2;
  margin-top: 60px;
}
.post-car_box2 .container .upload-div {
  display: flex;
  grid-column: 1;
  grid-row: 1;
}
.post-car_box2 .container .upload-div img, .post-car_box2 .container .upload-div svg {
  margin-right: 16px;
}
.post-car_box2 .container .upload-div p {
  margin-bottom: 8px;
}
.post-car_box2 .container .grid-sortable {
  grid-column: 1/-1;
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  justify-self: start;
}
.post-car_box2 .container .grid-sortable .container-btn-img {
  position: relative;
}
.post-car_box2 .container .grid-sortable .container-btn-img:hover .thumbInner {
  filter: brightness(0.3);
  cursor: move;
}
.post-car_box2 .container .grid-sortable .container-btn-img:hover .delete-icon {
  display: block;
  width: 20px;
  position: absolute;
  z-index: 500;
  top: 50%;
  right: 50%;
  filter: brightness(50) saturate(0);
  cursor: pointer;
}
.post-car_box2 .container .grid-sortable .container-btn-img .delete-icon {
  display: none;
}
.post-car_box2 .container .uploading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 126px;
  height: 126px;
}
.post-car_box2 .container .uploading svg {
  animation: spin 2s infinite linear;
}
.post-car_box2 .btn-large {
  max-height: 40px;
}
.post-car_box2 .thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  grid-column: 1/-1;
}
.post-car_box2 .thumb {
  display: inline-flex;
  border-radius: 16px;
  margin-bottom: 8px;
  margin-top: 16px;
  margin-right: 16px;
  width: 126px;
  height: 126px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.post-car_box2 .thumbInner {
  display: flex;
  min-width: 0px;
  overflow: hidden;
}
@media (max-width: 719px) {
  .post-car_box2 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
.post-car_box3 {
  display: flex;
  flex-direction: column;
  border: 2px solid #E8E8E8;
  border-radius: 16px;
  text-align: left;
  margin-bottom: 8px;
  padding: 41px 32px;
  justify-content: space-between;
  align-items: flex-start;
}
.post-car_box3 p {
  margin-bottom: 24px;
}
.post-car_box3 textarea {
  width: 100%;
  resize: none;
  border: 1.5px solid #A7A7A8;
  border-radius: 8px;
  padding: 10px 14px;
  font-family: "ToyotaType", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  outline: transparent;
}
.post-car_fifth_step {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .post-car_fifth_step {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .post-car_fifth_step {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.post-car_fifth_step .post-car_fifth_step_success {
  grid-column: 1/-1;
  background-color: #D1FADF;
  color: #027A48;
  height: 50px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 17px;
}
.post-car_fifth_step .post-car_fifth_step_success span {
  display: flex;
  align-items: center;
}
.post-car_fifth_step .post-car_fifth_step_success img, .post-car_fifth_step .post-car_fifth_step_success svg {
  width: 13.33px;
  height: 13.33px;
  margin-right: 17px;
}
.post-car_fifth_step .post-car_fifth_step_success button {
  border: none;
  background-color: transparent;
  justify-self: flex-end;
}
.post-car_fifth_step h2 {
  grid-column: 1/-1;
  margin-top: 25px;
  margin-bottom: 48px;
}
.post-car_fifth_step .back-button {
  grid-column: 1/-1;
  display: block;
  margin-bottom: 24px;
  margin-top: 24px;
}
.post-car_fifth_step .back-button img, .post-car_fifth_step .back-button svg {
  width: 11.67px;
  margin-right: 12px;
}
.post-car_fifth_step_left {
  grid-column: 1/span 15;
  height: 500px;
  margin-bottom: 80px;
}
.post-car_fifth_step_left .mySwiper2 {
  width: 100%;
  height: 460px;
  margin-bottom: 16px;
  border-radius: 18px;
}
.post-car_fifth_step_left .mySwiper2 img, .post-car_fifth_step_left .mySwiper2 svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-car_fifth_step_left .mySwiper2 .swiper-button-next, .post-car_fifth_step_left .mySwiper2 .swiper-button-prev {
  border-radius: 50%;
  background-color: white;
  opacity: 0.6;
  width: 36px;
  height: 36px;
}
.post-car_fifth_step_left .mySwiper2 .swiper-button-next:after, .post-car_fifth_step_left .mySwiper2 .swiper-rtl .swiper-button-prev:after {
  content: "next";
  color: black;
  font-size: 15px;
  font-weight: bold;
}
.post-car_fifth_step_left .mySwiper2 .swiper-button-prev:after, .post-car_fifth_step_left .mySwiper2 .swiper-rtl .swiper-button-next:after {
  content: "prev";
  color: black;
  font-size: 15px;
  font-weight: bold;
}
.post-car_fifth_step_left .mySwiper2 .swiper-pagination-fraction {
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
  font-family: "ToyotaType", sans-serif;
  padding: 8px 16px;
  border-radius: 24px;
  background-color: white;
  opacity: 0.6;
  width: max-content;
  margin-left: calc(50% - 40px);
  margin-bottom: 24px;
}
.post-car_fifth_step_left .mySwiper {
  width: 100%;
}
.post-car_fifth_step_left .mySwiper .swiper-slide {
  width: 126px;
  height: 126px;
  border-radius: 16px;
  overflow: hidden;
}
.post-car_fifth_step_left .mySwiper .swiper-slide img, .post-car_fifth_step_left .mySwiper .swiper-slide svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-car_fifth_step_left .description {
  height: 56px;
  border-bottom: 1px solid #BEBEC0;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 16px;
}
.post-car_fifth_step_right {
  grid-column: 17/-1;
  height: 500px;
  padding: 24px 0 0 16px;
  margin-top: -78px;
}
.post-car_fifth_step_right_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.post-car_fifth_step_right_1 p {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0em;
  color: #1A0060;
  background-color: #D2C7EF;
  border-radius: 16px;
  padding: 4px 10px;
}
.post-car_fifth_step_right_1 .--link-s {
  color: #D92D20;
  text-decoration: underline;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.post-car_fifth_step_right_1 .--link-s img, .post-car_fifth_step_right_1 .--link-s svg {
  width: 16.77px;
  height: 16.77px;
  margin-left: 10.5px;
}
.post-car_fifth_step_right_2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post-car_fifth_step_right_2 .--subtitle {
  color: #5C39E5;
}
.post-car_fifth_step_right_3 {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  margin-bottom: 16px;
}
.post-car_fifth_step_right_3 h1 {
  grid-row: 1;
}
.post-car_fifth_step_right_3 .model {
  font-weight: 400;
  grid-row: 2;
  grid-column: 1;
}
.post-car_fifth_step_right_3 .--link-s {
  grid-row: 2;
  grid-column: 2;
  text-decoration: underline;
  color: #3A00D5;
  align-self: end;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.post-car_fifth_step_right_3 .--link-s img, .post-car_fifth_step_right_3 .--link-s svg {
  width: 16.77px;
  height: 16.77px;
  margin-left: 8px;
}
.post-car_fifth_step_right_4 p {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #DEDEDF;
  color: #454545;
}
.post-car_fifth_step_right_5 {
  background-color: #F7F7F7;
  border-radius: 16px;
  padding: 24px 16px;
  font-weight: 700;
  color: #454545;
  margin-top: 16px;
}
.post-car_fifth_step_right_5 .title {
  padding-bottom: 16px;
  border-bottom: 1px solid #DEDEDF;
}
.post-car_fifth_step_right_5 .subtitle {
  margin-top: 16px;
  margin-bottom: 16px;
}
.post-car_fifth_step_right_5 span {
  background: #3A00D5;
  border: 1px solid #3A00D5;
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
}
.post-car_fifth_step_right_5 button {
  border-radius: 16px;
  padding: 16px 24px;
  border: 1px solid #3A00D5;
  margin-top: 16px;
  background-color: #FFFFFF;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5555555556;
  letter-spacing: 0em;
  color: #3A00D5;
  width: 100%;
  text-align: left;
}
.post-car_fifth_step .state_mobile {
  display: none;
}
.post-car_fifth_step .menu_mobile {
  display: none;
}
.post-car .disabled {
  pointer-events: none;
}
.post-car .modal_recomendations_container, .post-car .modal_delete_container {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.post-car .modal_recomendations, .post-car .modal_delete {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 510px;
  background-color: #FFFFFF;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  text-align: center;
}
.post-car .modal_recomendations h3, .post-car .modal_delete h3 {
  margin-top: 16px;
  margin-bottom: 8px;
}
.post-car .modal_recomendations p, .post-car .modal_delete p {
  margin-bottom: 32px;
}
.post-car .modal_recomendations .line, .post-car .modal_delete .line {
  width: 100%;
  height: 1px;
  background-color: #E8E8E8;
  margin-bottom: 16px;
}
.post-car .modal_recomendations .btn-large, .post-car .modal_delete .btn-large {
  width: 100%;
  max-width: 100%;
  margin-bottom: 16px;
}
.post-car .modal_recomendations .examples, .post-car .modal_delete .examples {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 32px;
}
@media (max-width: 575px) {
  .post-car .modal_recomendations, .post-car .modal_delete {
    margin-left: auto;
    margin-right: auto;
    width: 343px;
  }
}
@media (max-width: 1023px) {
  .post-car_text, .post-car_form {
    grid-column: 1/-1 !important;
  }
  .post-car_fifth_step {
    grid-template-rows: repeat(2, max-content);
  }
  .post-car_fifth_step_left {
    grid-column: 1/-1;
    height: auto;
  }
  .post-car_fifth_step_right {
    grid-column: 1/-1;
  }
  .post-car_fifth_step_right_1 {
    display: none;
  }
  .post-car_fifth_step_right_2 img, .post-car_fifth_step_right_2 svg {
    display: none;
  }
  .post-car_fifth_step_right_3 .link {
    display: none;
  }
  .post-car_fifth_step .state_mobile {
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0em;
    color: #1A0060;
    background-color: #D2C7EF;
    border-radius: 16px;
    padding: 4px 10px;
  }
  .post-car_fifth_step .menu_mobile {
    display: flex;
    grid-column: 7;
    justify-self: end;
    position: relative;
  }
  .post-car_fifth_step .menu_mobile_open {
    display: flex;
    position: absolute;
    right: 0;
    top: 25px;
    flex-direction: column;
    z-index: 5;
    width: 178px;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    background-color: #FFFFFF;
  }
  .post-car_fifth_step .menu_mobile_open .link {
    padding: 20px 14px;
  }
  .post-car_fifth_step .menu_mobile_open .link span {
    display: flex;
    justify-content: space-between;
  }
  .post-car_fifth_step .menu_mobile_open .link span img, .post-car_fifth_step .menu_mobile_open .link span svg {
    height: 16.7px;
  }
  .post-car_fifth_step .menu_mobile_open .link:first-child {
    border-bottom: 1px solid #EEEEEF;
  }
}
@media (max-width: 719px) {
  .post-car {
    align-content: start;
    padding-top: 60px;
  }
  .post-car_text {
    margin-top: 32px;
  }
  .post-car_form {
    margin-top: 32px;
  }
  .post-car_form .ego-form__field {
    min-width: 327px;
  }
  .post-car_btn {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
  }
  .post-car_box1 {
    border: none;
    flex-direction: column;
    grid-column: 1/-1;
    align-items: center;
  }
  .post-car_box1_left {
    padding: 0px;
    margin-bottom: 16px;
  }
  .post-car_box1_right {
    padding: 32px 8px;
    margin-top: 16px;
    text-align: center;
  }
  .post-car_box1_right .--text-m {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    letter-spacing: 0em;
  }
  .post-car_box2 {
    text-align: center;
    padding: 32px 16px;
  }
  .post-car_box2 .container .upload-div {
    grid-column: 1/-1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .post-car_box2 .container .upload-div img, .post-car_box2 .container .upload-div svg {
    margin: 0 auto;
  }
  .post-car_box2 .container .dropzone {
    grid-column: 1/-1;
    grid-row: 2;
    width: 100%;
    max-width: 400px;
  }
  .post-car_box2 .container .dropzone p {
    margin: 0 auto;
  }
  .post-car_box2 .container img, .post-car_box2 .container svg {
    margin-right: auto;
    margin-left: auto;
  }
  .post-car_box2 .container p {
    margin-top: 8px;
  }
  .post-car_box2 .container .grid-sortable {
    grid-template-columns: repeat(auto-fill, 140px);
    grid-auto-flow: inherit;
    justify-items: center;
    align-items: center;
    justify-self: unset;
  }
  .post-car_box2 .container .thumb {
    margin-right: none;
  }
  .post-car_box2 .container .thumb:hover .thumbInner {
    filter: brightness(1);
    cursor: move;
  }
  .post-car_box2 .container .thumb:hover .delete-icon {
    display: none;
  }
  .post-car_box2 .container .delete-icon {
    display: none;
  }
  .post-car_box2 .btn-large {
    margin-top: 24px;
  }
  .post-car_box3 {
    border: none;
    padding: 0;
  }
  .post-car_box3 p {
    margin-bottom: 16px;
  }
  .post-car_box3 textarea {
    margin-bottom: 69px;
  }
}
@media (max-width: 719px) and (max-width: 719px) {
  .post-car_box3 textarea {
    margin-bottom: 40px;
  }
}
@media (max-width: 670px) {
  .post-car {
    margin-top: 66px;
  }
}
@media (max-width: 575px) {
  .post-car_fifth_step_left {
    height: auto;
  }
  .post-car_fifth_step_left .mySwiper2 {
    width: 100%;
    height: 194px;
    margin-top: 21px;
  }
  .post-car_fifth_step_left .mySwiper {
    height: 87.5px;
  }
  .post-car_fifth_step_left .mySwiper img {
    height: 87.5px;
  }
  .post-car_box2 {
    text-align: center;
    padding: 32px 16px;
  }
  .post-car_box2 .container .grid-sortable {
    grid-template-columns: repeat(auto-fill, 102px);
    grid-auto-flow: inherit;
    justify-items: center;
    align-items: center;
    justify-self: unset;
  }
  .post-car_box2 .container .thumb {
    margin-right: none;
    width: 95px;
    height: 95px;
  }
}

@media (max-width: 575px) {
  section.post-car {
    padding-top: 0 !important;
  }
}
.precios_fotos .post-car_form {
  grid-column: 5/span 15;
}
.precios_fotos .post-car_form .post-car_third_step_success, .precios_fotos .post-car_form .post-car_fourth_step_success {
  background-color: #D1FADF;
  color: #027A48;
  height: 50px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 17px;
}
.precios_fotos .post-car_form .post-car_third_step_success span, .precios_fotos .post-car_form .post-car_fourth_step_success span {
  display: flex;
  align-items: center;
  text-align: left;
}
.precios_fotos .post-car_form .post-car_third_step_success span .--link-s, .precios_fotos .post-car_form .post-car_fourth_step_success span .--link-s {
  font-weight: 400;
}
.precios_fotos .post-car_form .post-car_third_step_success img, .precios_fotos .post-car_form .post-car_third_step_success svg, .precios_fotos .post-car_form .post-car_fourth_step_success img, .precios_fotos .post-car_form .post-car_fourth_step_success svg {
  width: 13.33px;
  height: 13.33px;
  margin-right: 17px;
}
.precios_fotos .post-car_form .post-car_third_step_success .btn-large, .precios_fotos .post-car_form .post-car_fourth_step_success .btn-large {
  border: none;
  background-color: transparent;
  justify-self: flex-end;
}
.precios_fotos .post-car_form .post-car_third_step_success .btn-large img, .precios_fotos .post-car_form .post-car_third_step_success .btn-large svg, .precios_fotos .post-car_form .post-car_fourth_step_success .btn-large img, .precios_fotos .post-car_form .post-car_fourth_step_success .btn-large svg {
  width: 10px;
  height: 10px;
}
@media (max-width: 719px) {
  .precios_fotos .post-car_form .post-car_third_step_success, .precios_fotos .post-car_form .post-car_fourth_step_success {
    height: auto;
    margin-bottom: 24px;
  }
}
.precios_fotos .btn_and_msg {
  display: flex;
  flex-direction: column;
}
.precios_fotos .btn_and_msg .btn_group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}
.precios_fotos .btn_and_msg .btn_group .third_step_btn {
  margin-bottom: 16px;
}
.precios_fotos .btn_and_msg .btn_group .third_step_btn .btn-large {
  max-width: 400px;
  min-width: 343px;
  width: 100%;
  display: block;
}
@media (max-width: 719px) {
  .precios_fotos .btn_and_msg .btn_group .third_step_btn {
    margin-top: 0px;
  }
}
@media (max-width: 719px) {
  .precios_fotos .btn_and_msg {
    flex-direction: column-reverse;
  }
}

.features_container {
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 80px 0 48px 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row: 2;
  grid-column: 5/20;
}
@media (max-width: 1300px) {
  .features_container {
    grid-column: 4/20;
  }
}
@media (max-width: 1200px) {
  .features_container {
    grid-column: 3/20;
  }
}
@media (max-width: 1023px) {
  .features_container {
    grid-column: 1/-1;
  }
}
@media (max-width: 850px) {
  .features_container {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 575px) {
  .features_container {
    margin: 24px 0 0 0;
  }
}

.features_box {
  display: flex;
  align-items: center;
  width: 254px;
  height: 72px;
  border-radius: 10px;
  border: 1px solid #D8D8D8;
  margin: 8px 6px;
  padding-left: 24px;
  cursor: pointer;
}
@media (max-width: 850px) {
  .features_box {
    width: 100%;
    margin: 8px 0;
    justify-content: center;
  }
}
.features_box.selected {
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  color: #3A00D5;
  border: 1px solid #3A00D5;
  background-color: #F1ECFF;
}

.features_btn {
  grid-row: 3;
  grid-column: 9/16;
  max-width: 400px;
  margin-bottom: 16px;
  justify-self: center;
}
@media (max-width: 1023px) {
  .features_btn {
    grid-column: 1/-1;
  }
}
@media (max-width: 850px) {
  .features_btn {
    position: fixed;
    bottom: 0;
  }
}

.features_view_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 32px;
}

.feature_view {
  display: flex;
  height: 24px;
  width: 260px;
  margin: 10px 10px 10px 0;
  align-items: center;
}
@media (max-width: 575px) {
  .feature_view {
    width: 100%;
  }
}

.paginator {
  display: flex;
  flex-flow: row nowrap;
}
.paginator__btn, .paginator__ellipsis {
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  border: 1px solid #DEDEDF;
  background-color: transparent;
  color: #3A00D5;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.paginator__btn:not(:last-child), .paginator__ellipsis:not(:last-child) {
  border-right: 0;
}
.paginator__btn:hover, .paginator__ellipsis:hover {
  background-color: #3A00D5;
  color: #FFFFFF;
}
.paginator__btn:hover svg, .paginator__ellipsis:hover svg {
  stroke: #FFFFFF;
}
.paginator__btn.--active, .paginator__ellipsis.--active {
  background-color: #1A0060;
  color: #FFFFFF;
}
.paginator__btn.--disabled, .paginator__ellipsis.--disabled {
  cursor: default;
  pointer-events: none;
  background-color: #E8E8E8;
  color: #A7A7A8;
}
.paginator__btn.--disabled svg, .paginator__ellipsis.--disabled svg {
  stroke: #A7A7A8;
}
.paginator__btn__prev, .paginator__ellipsis__prev {
  border-radius: 24px 0px 0px 24px;
}
.paginator__btn__prev .arrow, .paginator__ellipsis__prev .arrow {
  width: 12px;
  height: 12px;
  stroke: #3A00D5;
}
.paginator__btn__next, .paginator__ellipsis__next {
  border-radius: 0 24px 24px 0;
}
.paginator__btn__next .arrow, .paginator__ellipsis__next .arrow {
  width: 12px;
  height: 12px;
  stroke: #3A00D5;
  transform: rotate(180deg);
}
@media (max-width: 719px) {
  .paginator__btn__prev span, .paginator__btn__next span {
    display: none;
  }
}
.paginator__ellipsis {
  pointer-events: none;
  cursor: default;
}

.badge {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  color: #3A00D5;
  background-color: #E8E8E8;
  padding: 6px 12px;
  border-radius: 12px;
}
.badge.--white {
  background-color: #FFFFFF;
}
.badge.--dismissible {
  cursor: pointer;
}
.badge.--dismissible:after {
  content: "×";
  font-size: 21px;
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 0.619047619;
  letter-spacing: normal;
}
.badge.--state {
  font-weight: 700;
}
.badge.--state[data-state=draft] {
  background-color: #DEDEDF;
  color: #454545;
}
.badge.--state[data-state=published] {
  background-color: #D2C7EF;
  color: #1A0060;
}
.badge.--state[data-state=reserved] {
  background-color: #F1ECFF;
  color: #3A00D5;
}
.badge.--state[data-state=rejected] {
  background-color: #FEF3F2;
  color: #D92D20;
}
.badge.--state[data-state=completed] {
  background-color: #ECFDF3;
  color: #027A48;
}

.ellipsis {
  position: relative;
  display: block;
  width: 32px;
}
.ellipsis__button {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
}
.ellipsis__button .ellipsis__dots {
  width: 4px;
  height: 18px;
}
.ellipsis__button .ellipsis__dots * {
  stroke: #3A00D5;
  transition: stroke 0.2s ease-in-out;
}
.ellipsis__button:hover {
  background-color: #F7F7F7;
}
.ellipsis__button:hover .ellipsis__dots * {
  stroke: #1A0060;
}
.ellipsis__dropdown {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  z-index: 1;
  min-width: 160px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  padding: 2px 0;
  margin-left: -3px;
  margin-top: 8px;
  max-height: 250px;
  overflow-x: auto;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.ellipsis__dropdown::-webkit-scrollbar {
  width: 0.5em;
  padding-left: 10px;
}
.ellipsis__dropdown.--horizontal-scroll::-webkit-scrollbar {
  height: 0.5em;
}
.ellipsis__dropdown::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ellipsis__dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
}
.ellipsis__dropdown ul {
  display: flex;
  flex-direction: column;
}
.ellipsis__dropdown ul li {
  padding: 8px 0;
}
.ellipsis__dropdown ul li:not(:last-child) {
  border-bottom: 1px solid #E8E8E8;
}
.ellipsis__dropdown__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 0;
  text-align: left;
  cursor: pointer;
  padding: 8px 16px;
  background-color: transparent;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1428571429;
  letter-spacing: normal;
  transition: background-color 0.2s ease-in-out;
}
.ellipsis__dropdown__btn:hover {
  background-color: #F7F7F7;
}
.ellipsis.--open .ellipsis__dropdown {
  opacity: 1;
  pointer-events: all;
}
.ellipsis.--right {
  margin-left: auto;
}
.ellipsis.--right .ellipsis__dropdown {
  left: auto;
  right: 0;
}

.terms-conditions {
  padding-top: 100px;
  padding-bottom: 120px;
}
.terms-conditions_container {
  padding-top: 80px;
}
.terms-conditions_container .terms-conditions_title {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
.terms-conditions_container .accordion-container {
  margin-top: 120px;
}
.terms-conditions_container .accordion-container h1 {
  margin-bottom: 32px;
}
.terms-conditions_container .accordion-container li:first-child {
  border-top: none;
}
.terms-conditions_container .accordion-container li {
  border-bottom: 1px solid #DEDEDF;
  padding-top: 24px;
  padding-bottom: 24px;
}
.terms-conditions_container .accordion-container li .active, .terms-conditions_container .accordion-container li .accordion:hover {
  cursor: pointer;
}
.terms-conditions_container .accordion-container li .accordion {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.terms-conditions_container .accordion-container li .accordion .toggle-minus {
  display: none;
  transform: rotate(180deg);
}
.terms-conditions_container .accordion-container li .panel {
  display: none;
  overflow: hidden;
}
.terms-conditions_container .accordion-container li .panel .--text-m {
  margin-top: 16px;
}
@media (max-width: 1023px) {
  .terms-conditions_container .accordion-container {
    grid-column: 1/-1;
  }
}
@media (max-width: 719px) {
  .terms-conditions_container .faq_title, .terms-conditions_container .faq_text {
    text-align: left;
  }
  .terms-conditions_container .accordion-container {
    margin-top: 40px;
  }
}

.profile-offers__card-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.profile-offers__card-box {
  padding: 16px;
  border: 2px solid #E8E8E8;
  border-radius: 16px;
}
.profile-offers__card-box__data {
  display: flex;
  align-items: center;
  gap: 16px;
}
.profile-offers__card-box__data .icon {
  display: block;
}
.profile-offers__card-box__data .icon-rejected {
  display: none;
}
.profile-offers__card-box__data .amount {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: block;
}
.profile-offers__card-box__data span {
  font-size: 12px;
  line-height: 18px;
  display: block;
}
.profile-offers__card-box__controls {
  width: 100%;
  margin-top: 17px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.profile-offers__card-box__controls button {
  display: inline-block;
  flex-grow: 1;
}
.profile-offers__card-box.--rejected .profile-offers__card-box__data .icon {
  display: none;
}
.profile-offers__card-box.--rejected .profile-offers__card-box__data .icon-rejected {
  display: block;
}
.profile-offers__card-box.--rejected .profile-offers__card-box__data .amount::before {
  content: "Rechazaste ";
}
.profile-offers__card-box.--rejected .profile-offers__card-box__controls {
  display: none;
}

.comissionsModal .ego-modal__box {
  max-width: 590px;
  min-width: 340px;
}
.comissionsModal .ego-modal__box .ego-modal__box__content-header p {
  margin-top: 8px;
}
.comissionsModal .ego-form__footer button {
  max-width: 210px;
  margin: 8px auto 0 auto;
}
.comissionsModal .accordion-container-comissions li:first-child {
  border-bottom: 1px solid #8E6EE3;
}
.comissionsModal .accordion-container-comissions li {
  padding-top: 24px;
  padding-bottom: 24px;
}
.comissionsModal .accordion-container-comissions li .description {
  color: #454545;
  margin-right: 48px;
  margin-top: 8px;
}
.comissionsModal .accordion-container-comissions li .active, .comissionsModal .accordion-container-comissions li .accordion-comissions:hover {
  cursor: pointer;
}
.comissionsModal .accordion-container-comissions li .accordion-comissions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.comissionsModal .accordion-container-comissions li .accordion-comissions p {
  margin-right: 16px;
}
.comissionsModal .accordion-container-comissions li .accordion-comissions .toggle-minus {
  display: none;
}
.comissionsModal .accordion-container-comissions li .panel {
  display: none;
  overflow: auto;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.preloader.--hide {
  opacity: 0;
}

.status {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.status {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.status div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #3A00D5;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.status div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.status div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.status div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.status div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.header-post-car {
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: #FFFFFF;
  padding: 21px 0px;
  width: 100%;
  border-bottom: 1px solid #E8E8E8;
}
.header-post-car__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 9;
}
@media (max-width: 670px) {
  .header-post-car__content .btn-small.salir {
    position: absolute;
    right: 16px;
    width: 62px;
  }
}
.header-post-car__content .btn-small.volver {
  display: none;
}
@media (max-width: 910px) {
  .header-post-car__content .btn-small.volver {
    display: block;
    width: 74px;
  }
}
.header-post-car__content .steps {
  display: none;
  color: #3A00D5;
}
@media (max-width: 850px) {
  .header-post-car__content .steps {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.header-post-car__logo-container {
  max-width: 170px;
}
.header-post-car__logo-container a img {
  display: block;
  max-width: 100%;
  height: auto;
}
@media (max-width: 910px) {
  .header-post-car__logo-container .logo {
    display: none;
  }
}
.header-post-car__primary-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin-left: 10px;
}
.header-post-car__primary-nav .nav-link {
  border-bottom: 1px solid #D2C7EF;
  width: 192px;
  padding-bottom: 8px;
  color: #454545;
}
.header-post-car__primary-nav .icon-edit {
  background-color: #EEEEEF;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  padding: 2px;
  position: relative;
  display: inline-block;
  margin-right: 8px;
}
.header-post-car__primary-nav .icon-edit img {
  filter: contrast(0);
  position: absolute;
  left: 3px;
}
.header-post-car__primary-nav .icon-done {
  display: none;
  background-color: #D1FADF;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  padding: 2px;
  position: relative;
  margin-right: 8px;
}
.header-post-car__primary-nav .icon-done img {
  position: absolute;
  left: 3px;
  top: 3px;
}
.header-post-car__primary-nav .--active {
  color: #3A00D5;
}
.header-post-car__primary-nav .--active .icon-edit {
  background-color: #D2C7EF;
}
.header-post-car__primary-nav .--active .icon-edit img {
  filter: none;
}
.header-post-car__primary-nav .--done {
  color: #027A48;
}
.header-post-car__primary-nav .--done .icon-done {
  display: inline-block;
}
.header-post-car__primary-nav .--done .icon-edit {
  display: none;
}
@media (max-width: 1330px) {
  .header-post-car__primary-nav li {
    width: auto;
    max-width: 192px;
    min-width: 100px;
  }
}
@media (max-width: 1220px) {
  .header-post-car__primary-nav {
    justify-content: space-evenly;
  }
  .header-post-car__primary-nav .nav-link {
    border-bottom: 1px solid #D2C7EF;
    width: 140px;
  }
}
@media (max-width: 850px) {
  .header-post-car__primary-nav {
    display: none;
  }
}

.catalog__header {
  background-color: #F7F7F7;
}
.catalog__header-back {
  border-radius: 0px 0px 80px 80px;
  padding: 40px 0;
  background-color: #FFFFFF;
}
.catalog__header .--subtitle {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 8px;
}
@media (max-width: 719px) {
  .catalog__header-back {
    border-radius: 0px 0px 40px 40px;
    padding: 30px 0;
  }
  .catalog__header .--subtitle {
    margin-bottom: 0;
  }
}
.catalog__body {
  background-color: #F7F7F7;
  padding-top: 0;
}
.catalog__grid {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  align-items: flex-start;
  gap: 50px;
}
@media (max-width: 1023px) {
  .catalog__grid {
    grid-template-columns: 0.7fr 1.3fr;
  }
}
@media (max-width: 960px) {
  .catalog__grid {
    display: block;
  }
}
.catalog__grid__filters-desk .filters-form {
  margin-top: 20px;
}
@media (max-width: 960px) {
  .catalog__grid__filters-desk {
    display: none;
  }
}
.catalog__grid__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}
.catalog__grid__header .results {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: normal;
}
.catalog__grid__header .order {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  justify-content: flex-end;
  gap: 10px;
}
@media (max-width: 575px) {
  .catalog__grid__header .order {
    font-size: 12px;
  }
}
.catalog__grid__filter-controls {
  display: none;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}
@media (max-width: 960px) {
  .catalog__grid__filter-controls {
    display: flex;
  }
}
.catalog__grid__filter-badges {
  display: none;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 30px;
}
.catalog__grid__filter-badges:empty {
  display: none;
}
@media (max-width: 960px) {
  .catalog__grid__filter-badges {
    display: flex;
  }
}
.catalog__grid__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 16px;
}
@media (max-width: 1200px) {
  .catalog__grid__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 960px) {
  .catalog__grid__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 719px) {
  .catalog__grid__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 575px) {
  .catalog__grid__list {
    grid-template-columns: 1fr;
  }
}
.catalog__grid__pagination {
  margin-top: 24px;
  padding-top: 40px;
  border-top: 1px solid #DEDEDF;
}
.catalog__grid__pagination .paginator {
  justify-content: flex-end;
}
.catalog__no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
}
.catalog__no-results h5 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5555555556;
  letter-spacing: normal;
  margin-bottom: 8px;
}
.catalog__no-results p {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  color: #454545;
}
@media (max-width: 575px) {
  .catalog__no-results {
    min-height: 180px;
  }
}
.catalog__card {
  background-color: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in-out;
  height: 100%;
}
.catalog__card:hover {
  box-shadow: 0px 2.3px 2.7px -10px rgba(0, 0, 0, 0.024), 0px 6.4px 7.5px -10px rgba(0, 0, 0, 0.035), 0px 15.4px 18.1px -10px rgba(0, 0, 0, 0.046), 0px 51px 60px -10px rgba(0, 0, 0, 0.07);
}
.catalog__card__img {
  display: block;
  min-height: 220px;
  background: no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  padding: 16px 24px;
}
.catalog__card__description {
  padding: 15px 24px 24px;
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 220px);
}
.catalog__card__description .body-type {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.catalog__card__description .details {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  color: #454545;
  flex-grow: 1;
}
.catalog__card__description .price {
  display: block;
  margin-top: 8px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
}

.catalog__grid__filters-desk .filters-form__field-group {
  margin-bottom: 10px;
}
.catalog__grid__filters-desk .filters-form__field-group__head {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
}
.catalog__grid__filters-desk .filters-form__field-group__head legend {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
}
.catalog__grid__filters-desk .filters-form__field-group .ego-form__field {
  margin-bottom: 20px;
}
.catalog__grid__filters-desk .filters-form__field-group .ego-form__field.--checkbox:not(:last-child) {
  margin-bottom: 16px;
}
.catalog__grid__filters-desk .filters-form__two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
@media (max-width: 1200px) {
  .catalog__grid__filters-desk .filters-form__two-columns {
    display: block;
  }
}
.catalog__grid__filters-desk .filters-form__colors-grid {
  display: flex;
  flex-flow: row wrap;
  row-gap: 7px;
  column-gap: 3px;
  margin-bottom: 15px;
}
.catalog__grid__filters-desk .filters-form__colors-grid .--radio {
  min-width: 30px;
  width: auto;
  margin-bottom: 0;
}
.catalog__grid__filters-desk .filters-form footer {
  padding: 30px 0;
  background-color: #F7F7F7;
  border-top: 1px solid #DEDEDF;
  position: sticky;
  bottom: 0;
  z-index: 7;
}
.catalog__grid__filters-desk .filters-form footer .btn-small {
  width: 100%;
}

.catalog__grid__filters-mob {
  display: block;
  position: fixed;
  inset: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: 0px 0px 5.3px rgba(0, 0, 0, 0.028), 0px 0px 17.9px rgba(0, 0, 0, 0.042), 0px 0px 80px rgba(0, 0, 0, 0.07);
  transform: translate3d(0px, 100%, 0px);
  transition: transform 0.2s ease-in-out;
}
.catalog__grid__filters-mob.--open {
  transform: translate3d(0, 0%, 0);
}
.catalog__grid__filters-mob #filtersFormMob {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__header {
  padding: 22px 16px;
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E8E8E8;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__header__close {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url("../images/dropdown-arrow.svg") no-repeat center center transparent;
  background-size: contain;
  border: 0;
  outline: none;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__body {
  flex-grow: 1;
  overflow: auto;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs {
  overflow: hidden;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab {
  width: 100%;
  overflow: hidden;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 14px 16px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.3571428571;
  letter-spacing: normal;
  background: #FFFFFF;
  border-bottom: 1px solid #E8E8E8;
  cursor: pointer;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-label::after {
  content: "";
  width: 22px;
  height: 22px;
  background: url("../images/dropdown-arrow.svg") no-repeat center center transparent;
  background-size: contain;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-content {
  max-height: 0;
  padding: 0 16px;
  background: #F7F7F7;
  transition: all 0.2s ease-in-out;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-content .ego-form__field {
  margin-bottom: 32px;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-content .ego-form__field:last-child {
  margin-bottom: 0;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-content .two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-content .two-columns .ego-form__field {
  margin-bottom: 0;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-content .colors-grid {
  display: flex;
  flex-flow: row wrap;
  row-gap: 7px;
  column-gap: 3px;
  margin-bottom: 15px;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-content .colors-grid .--radio {
  min-width: 30px;
  width: auto;
  margin-bottom: 0;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-trigger {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-trigger:checked + .tab-label::after {
  transform: rotate(180deg);
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__tabs--tab .tab-trigger:checked ~ .tab-content {
  max-height: 100vh;
  padding: 16px;
}
.catalog__grid__filters-mob #filtersFormMob .filters-mob__footer {
  padding: 22px 16px;
  border-top: 1px solid #E8E8E8;
}

.vehicle-detail {
  display: grid;
  grid-template-columns: 1fr auto 480px;
  grid-auto-rows: auto auto 1fr auto;
  gap: 32px;
}
@media (max-width: 1200px) {
  .vehicle-detail {
    grid-template-columns: 1fr auto 340px;
  }
}
@media (max-width: 1023px) {
  .vehicle-detail {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-rows: max-content;
    column-gap: 0;
    gap: 20px;
  }
}
.vehicle-detail .left-side {
  grid-column: 1/span 2;
  grid-row: 1;
}
@media (max-width: 1023px) {
  .vehicle-detail .left-side {
    grid-column: 1;
  }
}
.vehicle-detail .right-side {
  grid-column: 3;
  grid-row: 1;
}
@media (max-width: 1023px) {
  .vehicle-detail .right-side {
    grid-column: 1;
    grid-row: 2;
  }
}
.vehicle-detail .--mobile {
  display: none;
}
@media (max-width: 1024px) {
  .vehicle-detail .--mobile {
    display: flex;
    flex-direction: column;
  }
}
.vehicle-detail__zone-disclaimer {
  display: flex;
  align-items: center;
  border: 1px solid #3A00D5;
  padding: 16px 23px;
  border-radius: 10px;
  gap: 8px;
  margin-bottom: 16px;
}
.vehicle-detail__zone-disclaimer p {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}
.vehicle-detail__header {
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  padding-top: 34px;
  margin-bottom: 16px;
  overflow: hidden;
}
.vehicle-detail__header__top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 8px;
  padding: 0 34px;
}
.vehicle-detail__header__top h1 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.0625;
  letter-spacing: 0.01em;
}
.vehicle-detail__header__details {
  display: flex;
  justify-content: space-between;
  padding: 0 34px;
}
.vehicle-detail__header__details .type-year-km {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.01em;
  color: #454545;
}
.vehicle-detail__header__details .type-year-km .type {
  text-transform: uppercase;
}
.vehicle-detail__header__details em {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.01em;
  color: #454545;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.vehicle-detail__header__details em img {
  margin-left: 8px;
  max-width: 20px;
  max-height: 20px;
}
.vehicle-detail__header__price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  width: 100%;
}
.vehicle-detail__header__price h2 {
  font-size: 48px;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.02em;
  color: #3A00D5;
}
.vehicle-detail__header__price h2.--monthly {
  padding-bottom: 44px;
}
.vehicle-detail__header__price h2.--monthly:empty {
  display: none;
}
.vehicle-detail__header__price h2.--monthly::after {
  content: " por mes*";
  vertical-align: baseline;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1666666667;
  letter-spacing: 0em;
}
@media (max-width: 1023px) {
  .vehicle-detail__header {
    padding-top: 24px;
  }
  .vehicle-detail__header .vehicle-detail__header__top, .vehicle-detail__header .vehicle-detail__header__details {
    padding: 0 12px;
  }
  .vehicle-detail__header .vehicle-detail__header__top h1, .vehicle-detail__header .vehicle-detail__header__details h1 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.4166666667;
    letter-spacing: normal;
  }
  .vehicle-detail__header .vehicle-detail__header__top .type-year-km, .vehicle-detail__header .vehicle-detail__header__top em, .vehicle-detail__header .vehicle-detail__header__details .type-year-km, .vehicle-detail__header .vehicle-detail__header__details em {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 2.3333333333;
    letter-spacing: normal;
  }
}
.vehicle-detail__header__status {
  padding: 0 34px 12px 34px;
}
.vehicle-detail__actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #454545;
  width: 100%;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: normal;
}
.vehicle-detail__actions.--disabled {
  pointer-events: none;
}
.vehicle-detail__actions.--disabled .ego-form__control {
  color: #E8E8E8;
  pointer-events: none;
  cursor: not-allowed;
}
.vehicle-detail__actions.--disabled .ego-form__field.--checkbox label {
  pointer-events: none;
  cursor: not-allowed;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.7142857143;
  letter-spacing: normal;
}
.vehicle-detail__actions.--disabled .btn-large, .vehicle-detail__actions.--disabled .btn-small {
  background-color: #E8E8E8;
  border-color: #E8E8E8;
  color: #454545;
  pointer-events: none;
  cursor: default;
}
.vehicle-detail__actions.--disabled .btn-price {
  pointer-events: none;
  border-color: #E8E8E8;
  background-color: transparent;
  color: #454545;
}
.vehicle-detail__actions.--disabled .btn-price.--selected {
  pointer-events: none;
  background-color: #E8E8E8;
  border-color: #E8E8E8;
  color: #454545;
}
.vehicle-detail__actions .payment-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px 16px;
  border-radius: 16px;
  position: relative;
}
.vehicle-detail__actions .payment-box h4 {
  margin-bottom: 0;
}
.vehicle-detail__actions .payment-box hr {
  border: 0;
  border-top: 1px solid #DEDEDF;
  margin: 0;
}
.vehicle-detail__actions .payment-box .pre-approval-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  height: 0;
  transition: height 0.2s ease-in-out;
}
.vehicle-detail__actions .payment-box .description {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  font-style: italic;
}
.vehicle-detail__actions .payment-box .btn-large.price-btn {
  border-color: #D2C7EF;
  padding: 4.5px 22px;
  text-transform: uppercase;
  border-radius: 0;
  width: max-content;
}
.vehicle-detail__actions .payment-box .btn-large.price-btn span {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 2;
  letter-spacing: normal;
}
.vehicle-detail__actions .payment-box .btn-large.price-btn.payment-box__cash {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.vehicle-detail__actions .payment-box .btn-large.price-btn.payment-box__cash.--rounded {
  border-radius: 16px;
  cursor: default;
}
.vehicle-detail__actions .payment-box .btn-large.price-btn.payment-box__finance {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.vehicle-detail__actions .payment-box .btn-large.price-btn.payment-box__finance .finance_break {
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
}
.vehicle-detail__actions .payment-box .btn-large.price-btn.--selected {
  background-color: #D2C7EF;
  border-color: #D2C7EF;
  color: #3A00D5;
}
.vehicle-detail__actions .payment-box .open-finance-modal {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.0714285714;
  letter-spacing: 0em;
}
.vehicle-detail__actions .payment-box__dealer {
  padding: 0 34px;
}
.vehicle-detail__actions .gray-box {
  background-color: #F1F1F1;
  width: 100%;
  padding: 24px 48px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vehicle-detail__actions .gray-box button {
  width: 100%;
  margin-bottom: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
@media (max-width: 1023px) {
  .vehicle-detail__actions .gray-box {
    padding: 24px 30px;
  }
  .vehicle-detail__actions .gray-box .ego-form__field__label {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.7142857143;
    letter-spacing: normal;
  }
}
.vehicle-detail__actions .ego-form__field.--checkbox {
  text-align: center;
  margin-bottom: 0;
}
.vehicle-detail__actions .iva-text {
  margin-bottom: 0px;
  padding-top: 8px;
  border-top: 1px solid #EEEEEF;
  font-style: italic;
  color: #454545;
}
.vehicle-detail__actions .vehicle-detail__commission .iva-text {
  margin-bottom: 0px;
  padding-top: 8px;
  border-top: 1px solid #EEEEEF;
  font-style: italic;
  color: #454545;
}
.vehicle-detail__actions .vehicle-detail__commission .textlink {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0;
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
  z-index: 2;
}
.vehicle-detail__actions .vehicle-detail__commission .detail {
  padding: 12px 0 6px;
  font-size: 12px;
  display: none;
  gap: 8px;
}
.vehicle-detail__actions .vehicle-detail__commission .detail p {
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.vehicle-detail__actions .vehicle-detail__commission .detail__final-price {
  font-size: 14px;
  font-weight: 800;
}
.vehicle-detail__actions .vehicle-detail__commission .detail.active {
  display: flex;
  flex-direction: column;
}
.vehicle-detail__certified {
  border: 1px, solid #E8E8E8;
  display: flex;
  align-items: center;
  padding: 16px;
  column-gap: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
}
.vehicle-detail__certified .icon {
  width: 80px;
  height: 80px;
  align-self: baseline;
}
.vehicle-detail__certified-text {
  color: #000000;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: normal;
  list-style: disc !important;
}
.vehicle-detail__certified-text li {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}
.vehicle-detail__certified.--mobile {
  flex-direction: row;
  margin-bottom: 12px;
}
.vehicle-detail__certified.--mobile .icon {
  width: 70px;
  height: 70px;
  align-self: center;
}
@media (max-width: 1024px) {
  .vehicle-detail__certified {
    display: none;
  }
}
.vehicle-detail__dealer {
  border: 1px, solid #E8E8E8;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 23px;
  border-radius: 16px;
  margin-bottom: 16px;
}
.vehicle-detail__dealer p {
  color: #454545;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: normal;
}
.vehicle-detail__dealer p.description {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  font-style: italic;
  max-width: 400px;
}
@media (max-width: 1024px) {
  .vehicle-detail__dealer {
    display: none;
  }
}
.vehicle-detail__favorite {
  display: flex;
  align-items: center;
}
.vehicle-detail__favorite .favorite-btn {
  margin-left: 16px;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
}
.vehicle-detail__favorite .favorite-btn .heart {
  fill: none;
}
.vehicle-detail__favorite .favorite-btn.filled .heart {
  fill: #3A00D5;
}
.vehicle-detail__gallery {
  grid-column: 1/span 2;
  max-width: 100%;
}
.vehicle-detail__gallery .swiper.gallery {
  margin-bottom: 16px;
}
.vehicle-detail__gallery .swiper.gallery .swiper-slide {
  border-radius: 18px;
}
.vehicle-detail__gallery .swiper.gallery .swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 32px);
  width: 64px;
  height: 35px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  padding-bottom: 2px;
  bottom: 16px;
}
.vehicle-detail__gallery .swiper.gallery .swiper-button-next,
.vehicle-detail__gallery .swiper.gallery .swiper-button-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  font-weight: bold;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.vehicle-detail__gallery .swiper.gallery .swiper-button-next:after,
.vehicle-detail__gallery .swiper.gallery .swiper-button-prev:after {
  font-size: 13px;
  color: #000000;
  font-weight: bold;
}
@media (max-width: 719px) {
  .vehicle-detail__gallery .swiper.gallery .swiper-slide {
    border-radius: 11px;
  }
  .vehicle-detail__gallery .swiper.gallery .swiper-pagination {
    font-size: 12px;
    width: 45px;
    height: 25px;
    left: calc(50% - 25px);
  }
  .vehicle-detail__gallery .swiper.gallery .swiper-button-next,
.vehicle-detail__gallery .swiper.gallery .swiper-button-prev {
    width: 25px;
    height: 25px;
  }
  .vehicle-detail__gallery .swiper.gallery .swiper-button-next:after,
.vehicle-detail__gallery .swiper.gallery .swiper-button-prev:after {
    font-size: 8px;
  }
}
.vehicle-detail__gallery .swiper.thumbs .swiper-slide {
  border-radius: 16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  transition: outline-color 0.2s ease-in-out;
}
.vehicle-detail__gallery .swiper.thumbs .swiper-slide-thumb-active {
  outline-color: #3A00D5;
}
@media (max-width: 1023px) {
  .vehicle-detail__gallery {
    grid-column: 1;
    max-width: calc(100vw - 32px);
  }
}
.vehicle-detail__description {
  border-top: 1px solid #E8E8E8;
  padding-top: 32px;
}
.vehicle-detail__description .--bottomline {
  border: none;
  padding-bottom: 0;
  margin-bottom: 24px;
}
.vehicle-detail__description.--desk {
  margin-top: 32px;
}
.vehicle-detail__description.--desk .features_view_container .feature_view {
  width: 244px;
}
.vehicle-detail__description.--desk .features_view_container .feature_view svg {
  margin-right: 16px;
}
@media (max-width: 575px) {
  .vehicle-detail__description.--desk .features_view_container .feature_view {
    width: 100%;
  }
}
.vehicle-detail__description.--mobile .features_view_container {
  width: 100%;
  margin-bottom: 12px;
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.vehicle-detail__description.--mobile .features_view_container svg {
  margin-right: 16px;
}
.vehicle-detail__description.--mobile .features_view_container p {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
}
.vehicle-detail__description.--mobile .features_view_container .feature_view {
  width: max-content;
}
.vehicle-detail__description.--mobile.first {
  border: none;
  padding-top: 12px;
}
@media (max-width: 1023px) {
  .vehicle-detail__description {
    grid-column: 1;
  }
  .vehicle-detail__description.--desk {
    display: none;
  }
}
.vehicle-detail__description h4 {
  color: #454545;
}
.vehicle-detail__description .body {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: normal;
}
.vehicle-detail__views-counter {
  margin-top: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #F7F7F7;
  font-size: 14px;
  border-radius: 8px;
}
.vehicle-detail__views-counter.--mobile {
  flex-direction: row;
  height: max-content;
  margin-top: 0;
}
@media (max-width: 1023px) {
  .vehicle-detail__views-counter {
    margin-top: 32px;
  }
  .vehicle-detail__views-counter.--desk {
    display: none;
  }
}

.features {
  margin-top: 32px;
}

#loginModal .ego-modal__box__content-header p, #zoneModal .ego-modal__box__content-header p {
  margin: 8px 0 32px;
}
#loginModal .ego-modal__box__close-btn, #zoneModal .ego-modal__box__close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 0;
  background: none;
  outline: none;
  padding: 5px;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#loginModal .ego-modal__box__close-btn .times, #zoneModal .ego-modal__box__close-btn .times {
  width: 14px;
  height: 14px;
}
#loginModal .ego-modal__box__close-btn .times *, #zoneModal .ego-modal__box__close-btn .times * {
  transition: stroke 0.2s ease-in-out;
  stroke: #3A00D5;
}
#loginModal .ego-modal__box__close-btn:hover .times *, #zoneModal .ego-modal__box__close-btn:hover .times * {
  stroke: #1A0060;
}
#loginModal .ego-modal__box #loginForm, #zoneModal .ego-modal__box #loginForm {
  margin-bottom: 32px;
}
#loginModal .ego-modal__box #loginForm .text-link, #zoneModal .ego-modal__box #loginForm .text-link {
  display: block;
  text-align: center;
  margin: 0 auto 24px;
}
#loginModal .ego-modal__box #loginForm .btn-large, #zoneModal .ego-modal__box #loginForm .btn-large {
  width: 100%;
}
#loginModal .ego-modal__box .--link-s, #zoneModal .ego-modal__box .--link-s {
  color: #3A00D5;
}

@media (max-width: 719px) {
  #zoneModal .ego-modal__wrapper {
    align-items: flex-start !important;
  }
}
#zoneModal .ego-modal__box {
  top: 50%;
  transform: translateY(-50%);
}
#zoneModal .ego-modal__box__content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#zoneModal .ego-modal__box__content-header svg {
  margin-bottom: 16px;
}
#zoneModal .ego-modal__box__content-header h2 {
  margin-bottom: 16px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
}
#zoneModal .ego-modal__box__content-header p {
  margin-bottom: 32px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}
#zoneModal .ego-modal__box .btn-large {
  width: 100%;
}
@media (max-width: 719px) {
  #zoneModal .ego-modal__box {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.tabs-profile {
  background-color: #F7F7F7;
  padding-top: 0;
}

.profile__empty-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 610px;
  margin: 120px auto;
}
.profile__empty-tab h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
}
@media (max-width: 719px) {
  .profile__empty-tab h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
.profile__empty-tab p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #454545;
  margin-bottom: 32px;
}
@media (max-width: 719px) {
  .profile__empty-tab p {
    font-size: 16px;
    line-height: 24px;
  }
}
.profile__header {
  background-color: #F7F7F7;
}
.profile__header-back {
  border-radius: 0px 0px 80px 80px;
  padding: 40px 0;
  background-color: #FFFFFF;
}
.profile__header__name {
  grid-area: name;
}
.profile__header__cta {
  grid-area: cta;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  letter-spacing: normal;
  text-decoration: underline;
  color: #3A00D5;
}
.profile__header .--subtitle {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 8px;
  grid-area: sub;
}
.profile__header__box {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
  grid-template-areas: "sub ." "name cta";
}
@media (max-width: 719px) {
  .profile__header__box {
    grid-template-areas: "sub ." "name name" ". cta";
  }
}
@media (max-width: 719px) {
  .profile__header-back {
    border-radius: 0px 0px 40px 40px;
    padding: 30px 0;
  }
  .profile__header .--subtitle {
    margin-bottom: 0;
  }
}
.profile__content .login-post-signup .container {
  min-height: auto;
}
.profile__content .login-post-signup .container .content {
  grid-column: 9/span 7;
}
.profile__content .login-post-signup .container .content h1, .profile__content .login-post-signup .container .content p {
  text-align: center;
}

.contact-data {
  text-align: center;
  margin-bottom: 8px;
}
.contact-data h5 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #3A00D5;
}
.contact-data ul {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  color: #454545;
}

.accordion-bg__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.accordion-bg__item .accordion-dropdown {
  display: block;
}
.accordion-bg__item .accordion-upward, .accordion-bg__item .accordion-bg__inner {
  display: none;
}
.accordion-bg__item.active .accordion-dropdown {
  display: none !important;
}
.accordion-bg__item.active .accordion-upward, .accordion-bg__item.active .accordion-bg__inner {
  display: block !important;
}
.accordion-bg__inner {
  display: none;
  margin: 16px 0 40px;
  width: 100%;
}
.accordion-bg__inner__box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.accordion-bg__btn {
  border: none;
  margin: 0;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 22px 7px 16px;
  background-color: #F1ECFF;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}
.accordion-bg__btn span {
  color: #3A00D5;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: normal;
}

.car-list {
  background-color: #FFFFFF;
  border-radius: 16px;
}
.car-list hr {
  margin: 0;
  border: none;
  border-bottom: 1px solid #F7F7F7;
}
.car-list__header, .car-list__content-box {
  padding: 14px 27px 14px 16px;
}
@media (max-width: 575px) {
  .car-list__header, .car-list__content-box {
    padding-right: 14px;
  }
}
.car-list.--has-offers {
  padding: 3px 3px 23px;
  background-color: #D1FADF;
}
.car-list.--has-offers .car-list__wrapper {
  background-color: #FFFFFF;
  border-radius: 16px;
}
.car-list.--pre-expired {
  padding: 3px 3px 23px;
  background-color: #FEE4E2;
}
.car-list.--pre-expired .car-list__wrapper {
  background-color: #FFFFFF;
  border-radius: 16px;
}
.car-list__offer-box {
  padding: 18px 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1023px) {
  .car-list__offer-box {
    flex-direction: column;
  }
}
.car-list__offer-box--left {
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  .car-list__offer-box--left {
    margin-bottom: 16px;
  }
}
@media (min-width: 1440px) {
  .car-list__offer-box--right .btn-small {
    min-width: 170px;
    display: inline-block;
  }
}
@media (max-width: 575px) {
  .car-list__offer-box--right {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .car-list__offer-box--right .btn-small {
    display: inline-block;
    min-width: auto;
  }
}
.car-list__offer-box__label {
  margin-left: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #05603A;
  display: inline-block;
}
.car-list__offer-box__label b {
  font-weight: 700;
}
.car-list__offer-box__bell {
  flex-shrink: 0;
}
.car-list__offer-box__reject-btn {
  margin-right: 16px;
  border: none !important;
}
.car-list__expiration-box {
  padding: 18px 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1023px) {
  .car-list__expiration-box {
    flex-direction: column;
  }
}
.car-list__expiration-box--left {
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  .car-list__expiration-box--left {
    margin-bottom: 16px;
  }
}
@media (min-width: 1440px) {
  .car-list__expiration-box--right .btn-small {
    min-width: 170px;
    display: inline-block;
  }
}
@media (max-width: 575px) {
  .car-list__expiration-box--right {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .car-list__expiration-box--right .btn-small {
    display: inline-block;
    min-width: auto;
  }
}
.car-list__expiration-box__label {
  margin-left: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #912018;
  display: inline-block;
}
.car-list__expiration-box__label b {
  font-weight: 700;
}
.car-list__expiration-box__bell {
  flex-shrink: 0;
}
.car-list__expiration-box__reject-btn {
  margin-right: 16px;
  border: none !important;
}
.car-list__expiration-box.--renovada {
  display: none;
}
.car-list__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.car-list__header__date {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.3333333333;
  letter-spacing: normal;
  color: #454545;
}
.car-list__content {
  display: grid;
  grid-template-columns: 120px 1fr auto;
  column-gap: 24px;
  align-items: center;
  grid-template-areas: "img state ." "img model price" "img km-date cta";
}
@media (max-width: 575px) {
  .car-list__content {
    grid-template-columns: 1fr 2fr;
    column-gap: 8px;
    grid-template-areas: "img model" "img km-date" "img price" "state cta";
  }
}
.car-list__content__state {
  grid-area: state;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: normal;
  padding-top: 10px;
}
@media (max-width: 719px) {
  .car-list__content__state {
    padding-top: 8px;
  }
}
.car-list__content__state[data-state=draft] {
  color: #454545;
}
.car-list__content__state[data-state=draft] svg * {
  fill: #454545;
}
.car-list__content__state[data-state=published] {
  color: #1A0060;
}
.car-list__content__state[data-state=published] svg * {
  fill: #1A0060;
}
.car-list__content__state[data-state=reserved] {
  color: #3A00D5;
}
.car-list__content__state[data-state=reserved] svg * {
  fill: #3A00D5;
}
.car-list__content__state[data-state=rejected] {
  color: #D92D20;
}
.car-list__content__state[data-state=rejected] svg * {
  fill: #D92D20;
}
.car-list__content__state[data-state=cancelled] {
  color: #D92D20;
}
.car-list__content__state[data-state=cancelled] svg * {
  fill: #D92D20;
}
.car-list__content__state[data-state=completed] {
  color: #039855;
}
.car-list__content__state[data-state=completed] svg * {
  fill: #039855;
}
.car-list__content__model {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: normal;
  grid-area: model;
}
@media (max-width: 719px) {
  .car-list__content__model {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.4285714286;
    letter-spacing: normal;
    padding-top: 10px;
  }
}
.car-list__content__model span {
  font-weight: 700;
}
.car-list__content__price {
  grid-area: price;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
  margin-right: 24px;
}
@media (min-width: 720px) {
  .car-list__content__price {
    justify-self: end;
  }
}
@media (max-width: 719px) {
  .car-list__content__price {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: normal;
    padding-bottom: 20px;
  }
}
.car-list__content__km-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  color: #454545;
  grid-area: km-date;
}
@media (min-width: 720px) {
  .car-list__content__km-date {
    padding-bottom: 20px;
  }
}
@media (max-width: 719px) {
  .car-list__content__km-date {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: normal;
  }
}
.car-list__content__km-date svg {
  fill: #454545;
}
.car-list__content__cta {
  grid-area: cta;
  justify-self: end;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  letter-spacing: normal;
  color: #3A00D5;
  text-decoration: underline;
}
@media (min-width: 720px) {
  .car-list__content__cta {
    padding-bottom: 20px;
    margin-right: 24px;
  }
}
@media (max-width: 719px) {
  .car-list__content__cta {
    margin-right: 16px;
    margin-bottom: 16px;
    padding-top: 8px;
  }
}
.car-list__content__img {
  grid-area: img;
}
.car-list__content__img img {
  border-radius: 10px;
  width: 120px;
  height: 80px;
  object-fit: cover;
  margin: 16px 0 16px 16px;
}
@media (max-width: 719px) {
  .car-list__content__img img {
    height: 90px;
  }
}

.tabs {
  position: relative;
}
.tabs-list {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  align-items: center;
  width: 100%;
  overflow: scroll;
  scrollbar-width: none;
  text-align: center;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375;
  letter-spacing: normal;
}
@media (max-width: 575px) {
  .tabs-list {
    justify-content: space-between;
    gap: 8px;
  }
}
.tabs-list::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
.tabs-list__item {
  display: inline-block;
  padding: 10px 15px;
  flex-shrink: 0;
}
.tabs-list__item.active {
  border-bottom: 2px solid #3A00D5;
  color: #3A00D5;
  z-index: 1;
}
.tabs-buttons {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}
.tabs-buttons::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid #D8D8D8;
  width: 100%;
}
.tabs-panel {
  display: none;
  flex-direction: column;
  gap: 32px;
  animation: contentEffect 0.5s;
}
.tabs .profile__header__cta {
  position: absolute;
  right: 0;
  top: -34px;
  cursor: pointer;
}

@keyframes contentEffect {
  from {
    opacity: 0;
    transform: translateY(70%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
.profile-offers__card-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.profile-offers__card-box {
  padding: 16px;
  border: 2px solid #E8E8E8;
  border-radius: 16px;
}
.profile-offers__card-box__data {
  display: flex;
  align-items: center;
  gap: 16px;
}
.profile-offers__card-box__data .icon {
  display: block;
}
.profile-offers__card-box__data .icon-rejected {
  display: none;
}
.profile-offers__card-box__data .amount {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: block;
}
.profile-offers__card-box__data span {
  font-size: 12px;
  line-height: 18px;
  display: block;
}
.profile-offers__card-box__controls {
  width: 100%;
  margin-top: 17px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.profile-offers__card-box__controls button {
  display: inline-block;
  flex-grow: 1;
}
.profile-offers__card-box.--rejected .profile-offers__card-box__data .icon {
  display: none;
}
.profile-offers__card-box.--rejected .profile-offers__card-box__data .icon-rejected {
  display: block;
}
.profile-offers__card-box.--rejected .profile-offers__card-box__data .amount::before {
  content: "Rechazaste ";
}
.profile-offers__card-box.--rejected .profile-offers__card-box__controls {
  display: none;
}

.comissionsModal .ego-modal__box {
  max-width: 590px;
  min-width: 340px;
}
.comissionsModal .ego-modal__box .ego-modal__box__content-header p {
  margin-top: 8px;
}
.comissionsModal .ego-form__footer button {
  max-width: 210px;
  margin: 8px auto 0 auto;
}
.comissionsModal .accordion-container-comissions li:first-child {
  border-bottom: 1px solid #8E6EE3;
}
.comissionsModal .accordion-container-comissions li {
  padding-top: 24px;
  padding-bottom: 24px;
}
.comissionsModal .accordion-container-comissions li .description {
  color: #454545;
  margin-right: 48px;
  margin-top: 8px;
}
.comissionsModal .accordion-container-comissions li .active, .comissionsModal .accordion-container-comissions li .accordion-comissions:hover {
  cursor: pointer;
}
.comissionsModal .accordion-container-comissions li .accordion-comissions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.comissionsModal .accordion-container-comissions li .accordion-comissions p {
  margin-right: 16px;
}
.comissionsModal .accordion-container-comissions li .accordion-comissions .toggle-minus {
  display: none;
}
.comissionsModal .accordion-container-comissions li .panel {
  display: none;
  overflow: auto;
}

.comissions_table {
  color: #000000;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 24px;
}
.comissions_table .table-head {
  background-color: #DEDEDF;
  border-radius: 10px;
  display: grid;
  align-items: center;
  font-weight: 700;
}
.comissions_table .table-content {
  display: grid;
  align-items: center;
  padding: 8px 24px;
  border-bottom: 1px solid #DEDEDF;
}
.comissions_table .table-content:last-child {
  border-bottom: none;
}
.comissions_table.b2c .table-head, .comissions_table.b2c .table-content {
  grid-template-columns: 1fr 1fr;
  padding: 5px 22px;
  gap: 16px;
}
.comissions_table.c2c {
  width: max-content;
  padding-bottom: 8px;
  width: 100%;
}
.comissions_table.c2c .table-head, .comissions_table.c2c .table-content {
  grid-template-columns: 155px 114px 105px 105px;
  padding: 5px 11px;
  gap: 10px;
  justify-content: space-between;
  width: max-content;
}

.--richtext {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: normal;
}
.--richtext b, .--richtext strong {
  font-weight: bold;
}
.--richtext a {
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
}
.--richtext a:hover {
  text-decoration: none;
}
.--richtext p {
  margin-bottom: 1.5rem;
}
.--richtext img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 2rem 0;
}
.--richtext hr {
  display: block;
  margin: 1.5rem 0;
  border-top: 1px solid #DEDEDF;
  border-bottom: 0;
}
.--richtext hr ~ p {
  margin-top: 2rem;
}
.--richtext ul {
  padding-left: 25px;
  list-style: disc;
}
.--richtext ul li {
  margin-bottom: 24px;
  padding-left: 16px;
}
@media (min-width: 720px) {
  .--richtext ul li {
    padding-left: 20px;
  }
}
@media (min-width: 1024px) {
  .--richtext ul li {
    padding-left: 40px;
  }
}
@media (max-width: 1439px) {
  .--richtext {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .--richtext {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    letter-spacing: normal;
  }
}

.--primary-color {
  color: #3A00D5;
}

.--center-text {
  text-align: center;
}

.--monthly:empty {
  display: none;
}
.--monthly::after {
  content: "/por mes*";
  vertical-align: baseline;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
}

.--hidden {
  display: none;
}