.login-post-signup {
    .container {
        padding-top: 40px;
        .content {

            .primary-link {
                display: block;
                text-align: center;
            }

            .primary-btn {
                margin: 28px auto 32px auto;
            }

            .--link-s {
                color: $primary-color;
            }
        }
    }


    @include device-md() {
        .container {
            .content {

                h1 {
                    display: block;
                }

                .--link-s {
                    color: #E30018;
                }
            }
        }
    }
}