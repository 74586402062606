.faq {
    background-color: #F5F5F5;
    padding-top: 100px;
    padding-bottom: 120px;

    &_container {
        @include main-grid();
        place-content: center;
        padding-top: 80px;
        
        .faq_title, .faq_text {
            text-align: center;
            grid-column: 1 / -1;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .faq_text {
            color: $gray;
        }

        .accordion-container {
            grid-column: 5 / span 15;
            margin-top: 120px;

            h1 {
                margin-bottom: 32px;
            }

            li:first-child {
                border-top: 1px solid #8E6EE3;
            }

            li {
                border-bottom: 1px solid #8E6EE3;
                padding-top: 24px;
                padding-bottom: 24px;


                .description {
                    color: $gray;
                    margin-right: 48px;
                    margin-top: 8px;
                }

                .active, .accordion:hover {
                    cursor: pointer;
                }

                .accordion {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .toggle-minus {
                        display: none;
                    }
                }
        
                .panel {
                    display: none;
                    overflow: hidden;
                }
            }
        }

        @include device-lg {
            .accordion-container {
                grid-column: 1 / -1;
            }
        }

        @include device-md {
            .faq_title, .faq_text {
                text-align: left;
            }
            
        .accordion-container {
            margin-top: 40px;
        }
        }
    }
}