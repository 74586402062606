.paginator {
    display: flex;
    flex-flow: row nowrap;

    &__btn,
    &__ellipsis {
        display: flex;
        flex-flow: row nowrap;
        gap: 5px;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 15px;
        border: 1px solid $mid-gray;
        background-color: transparent;
        color: $primary-color;
        @include font-size(14, 20);
        cursor: pointer;
        transition: all $timming ease-in-out;

        &:not(:last-child) {
            border-right: 0;
        }

        &:hover {
            background-color: $primary-color;
            color: $white;

            svg {
                stroke: $white;
            }
        }

        &.--active {
            background-color: $primary-color-darker;
            color: $white;
        }

        &.--disabled {
            cursor: default;
            pointer-events: none;
            background-color: $light-gray;
            color: #A7A7A8;

            svg {
                stroke: #A7A7A8;
            }
        }

        &__prev {
            border-radius: 24px 0px 0px 24px;

            .arrow {
                width: 12px;
                height: 12px;
                stroke: $primary-color;
            }
        }

        &__next {
            border-radius: 0 24px 24px 0;
            .arrow {
                width: 12px;
                height: 12px;
                stroke: $primary-color;
                transform: rotate(180deg);
            }
        }
    }

    @include device-md {
        &__btn {
            &__prev,
            &__next {
                span {
                    display: none;
                }
            }
        }
    }

    &__ellipsis {
        pointer-events: none;
        cursor: default;
    }
}