.car-on-the-right {
    position: relative;
    padding-top: 100px;
    overflow: hidden;

    .container {
        min-height: calc(100vh - 100px);
        @include main-grid;
        align-content: center;

        .content {
            grid-column: 1 / span 7;

            .h1-mb-32 {
                margin-bottom: 32px;
            }

            .h1-mb-12 {
                margin-bottom: 12px;
            }

            h1 {
                text-align: left;
                display: flex;
                flex-direction: column;
            }
            
            .primary-btn {
                margin-top: 8px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
            }

            a {
                font-weight: 700;
                text-align: center;
                display: block;
            }

            p {
                color: $gray;
                margin-bottom: 32px;

                a {
                    display: inline;
                }
            }
        }

    }

    .login-img-d {
        position: absolute;
        right: 0;
        left: 50%;
        top: 84px;
        height: calc(100% - 84px);
        border-radius: 80px 0px 0px 80px;
        background-size: contain;
        background-position: left center !important;
        background-repeat: no-repeat;
    }

    .login-img-m {
        display: none;
    }

    @include rwd(1250) {
        .container {
            .content {
                grid-column: 1 / span 8;
            }
        }

        .login-img-d {
            left: 50%;
        }
    }

    @include device-lg() {
        .container {
            .content {
                grid-column: 1 / span 5;
            }
        }

        .login-img-d {
            left: 55%;
        }
    }

    @include device-md() {
        // min-height: 100vh;
        padding-bottom: 0;
        text-align: center;
        display: block;

        .container {
            min-height: auto;
            margin: auto;

            .content {
                grid-column: 1 / -1;

                h1, p {
                    text-align: center;
                }

                .text-align-left-mobile {
                    text-align: left;
                }

                .primary-btn, .general-error {
                    max-width: 400px;
                }

                .primary-btn {
                    margin-bottom: 102px;
                }
            }
        }

        .login-img-d {
            display: none;
        }

        .login-img-m {
            display: block;
            width: 100%;
            // height: 192px;
            height: 800px;
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
