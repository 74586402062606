.car-list {
    background-color: $white;
    border-radius: 16px;
    hr {
        margin: 0;
        border: none;
        border-bottom: 1px solid $lighter-gray;
    }
    &__header, &__content-box {
        padding: 14px 27px 14px 16px;
        @include device-sm{
            padding-right: 14px;
          }
    } 
    &.--has-offers {
        padding: 3px 3px 23px;
        background-color: #D1FADF;
        .car-list__wrapper {
            background-color: #FFFFFF;
            border-radius: 16px;
        }
    }
    &.--pre-expired {
        padding: 3px 3px 23px;
        background-color: #FEE4E2;
        .car-list__wrapper {
            background-color: #FFFFFF;
            border-radius: 16px;
        }
    }
    &__offer-box{
        padding: 18px 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include device-lg{
          flex-direction: column;
        }
        &--left{
            display: flex;
            align-items: center;
            @include device-lg{
                margin-bottom: 16px;
              }
        }
        &--right{
            @include device-xl("min"){
                .btn-small {
                    min-width: 170px;
                    display: inline-block;
                }
            }
            @include device-sm{
                width: 100%;
                display: flex;
                align-items: center;
                .btn-small {
                    display: inline-block;
                    min-width: auto;
                }
              }
        }
        &__label{
            margin-left: 10px;
            font-size: 14px;
            line-height: 20px;
            color: #05603A;
            display: inline-block;
            b{
                font-weight: 700;
            }
        }
        &__bell {
            flex-shrink: 0;
        }
        &__reject-btn{
            margin-right: 16px;
            border: none !important;
        }
    }
    &__expiration-box{
        padding: 18px 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include device-lg{
            flex-direction: column;
        }
        &--left{
            display: flex;
            align-items: center;
            @include device-lg{
                margin-bottom: 16px;
            }
        }
        &--right{
            @include device-xl("min"){
                .btn-small {
                    min-width: 170px;
                    display: inline-block;
                }
            }
            @include device-sm{
                width: 100%;
                display: flex;
                align-items: center;
                .btn-small {
                    display: inline-block;
                    min-width: auto;
                }
            }
        }
        &__label{
            margin-left: 10px;
            font-size: 14px;
            line-height: 20px;
            color: #912018;
            display: inline-block;
            b{
                font-weight: 700;
            }
        }
        &__bell {
            flex-shrink: 0;
        }
        &__reject-btn{
            margin-right: 16px;
            border: none !important;
        }
        &.--renovada{
            display: none;
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__date {
            @include font-size(12, 16, 700);
            color: $gray;
        } 
    }
    &__content {
        display: grid;
        grid-template-columns: 120px 1fr auto;
        column-gap: 24px;
        align-items: center;
        
        grid-template-areas:
        "img state ." 
        "img model price"
        "img km-date cta";
        
        @include device-sm{
            grid-template-columns: 1fr 2fr;
            column-gap: 8px;
            grid-template-areas:
            "img model" 
            "img km-date"
            "img price"
            "state cta";
        }

        &__state{
            grid-area: state;
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            @include font-size(12, 18, 700);
            
            padding-top: 10px;
            @include device-md{
                padding-top: 8px;
            }

            &[data-state="draft"] {
                color: $gray;
                svg * {
                    fill: $gray;
                }
            }
            
            &[data-state="published"] {
                color: $primary-color-darker;
                svg * {
                    fill: $primary-color-darker;
                }
            }
            &[data-state="reserved"]{
                color: $primary-color;
                svg * {
                    fill: $primary-color;
                }
            }
            &[data-state="rejected"]{
                color: #D92D20;
                svg * {
                    fill: #D92D20;
                }
            }
            &[data-state="cancelled"]{
                color: #D92D20;
                svg * {
                    fill: #D92D20;
                }
            }
            &[data-state="completed"] {
                color: #039855;
                svg * {
                    fill: #039855;
                }
            }
        }
        &__model{
            @include font-size(20, 24, 600);
            grid-area: model;
            @include device-md{
                @include font-size(14, 20, 600);
                padding-top: 10px;
            }
            span {
                font-weight: 700;
            }
        }
        &__price{
            grid-area: price;
            @include font-size(20, 24, 700);
            margin-right: 24px;
            @include device-md("min"){
                justify-self: end;
            }
            @include device-md{
                @include font-size(16, 24, 700);
                padding-bottom: 20px;
            }
        }
        &__km-date{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            @include font-size(12, 18, 400);
            color: $gray;
            grid-area: km-date;
            @include device-md("min"){
                padding-bottom: 20px;
            }
            @include device-md{
                @include font-size(12, 18, 400);
            }
            svg{
                fill: $gray;
            }
        }
        &__cta{
            grid-area: cta;
            justify-self: end;
            @include font-size(14, 20, 700);
            color: $primary-color;
            text-decoration: underline;
            @include device-md("min"){
                padding-bottom: 20px;
                margin-right: 24px;
            }
            @include device-md{
                margin-right: 16px;
                margin-bottom: 16px;
                padding-top: 8px;
            }
        }
        &__img{
            grid-area: img;
            img {
                border-radius: 10px;
                width: 120px;
                height: 80px;
                object-fit: cover;
                margin: 16px 0 16px 16px;
                @include device-md{
                    height: 90px;
                }
            }
        }
    }
}