.featured {
    background-color: $lighter-gray;
    padding: 20px 0 100px 0;
    overflow: hidden;
    @include device-sm{
        padding: 60px 0;
    }

    &__header {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 20px;
        @include device-sm{
            margin-bottom: 46px;
        }

        h2 {
            @include font-size(16, 16, 700);
            color: $primary-color;
        }
        h3 {
            @include font-size(32, 40, 700);
            @include device-sm{
                @include font-size(26, 34, 700);
            }
        }
    }

    // Swiper Styles
    #featured-section-swiper {
        @include device-sm{
            overflow: visible;
        }
        .swiper-wrapper {
            padding-bottom: 60px;
            .swiper-slide {
                height: auto;
                a {
                    margin: auto;
                    display: block;
                    height: 100%;
                }
            }
        }
    }

    .swiper-controls {
        @include device-lg{
            display: none;
        }
        .swiper-button-prev {
            left: 10px;
        }
        .swiper-button-next {
            right: 10px
        }
        .swiper-button-prev, .swiper-button-next {
            background-color: $white;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            transition: background-color .1s ease-in-out;

            svg {
                path {
                    transition: stroke .1s ease-in-out;
                }
            }

            &:after {
                content: '';
            }

            &:hover {
                background-color: $primary-color;
                svg {
                    path {
                        stroke: $white;
                    }
                }
            }
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            width: 13px;
            height: 10px;
            display: inline-block;
            background: $gray;;
            opacity: .2;
            -webkit-border-radius: 20px;
            margin: 0 10px !important;
            border-radius: 20px;
            -webkit-transition: opacity .2s, background-color .5s, width .5s;
            -o-transition: opacity .2s, background-color .5s, width .5s;
            transition: opacity .2s, background-color .5s, width .5s;

            &-active {
                opacity: 1;
                background: $gray;
                width: 40px;
                -webkit-transition-delay: 0s;
                -o-transition-delay: 0s;
                transition-delay: 0s;
            }
        }        

    }
}