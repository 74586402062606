.vehicle-detail {
    display: grid;
    grid-template-columns: 1fr auto 480px;
    grid-auto-rows: auto auto 1fr auto;
    gap: 32px;

    @include rwd(1200) {
        grid-template-columns: 1fr auto 340px;
    }

    @include device-lg {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-auto-rows: max-content;
        column-gap: 0;
        gap: 20px;
    }

    .left-side {
        grid-column: 1 / span 2;
        grid-row: 1;

        @include device-lg {
            grid-column: 1;
        }
    }

    .right-side {
        grid-column: 3;
        grid-row: 1;

        @include device-lg {
            grid-column: 1;
            grid-row: 2;
        }
    }

    .--mobile {
        display: none;

        @include rwd(1024) {
            display: flex;
            flex-direction: column;
        }
    }
    
    &__zone-disclaimer {
        display: flex;
        align-items: center;
        border: 1px solid $primary-color;
        padding: 16px 23px;
        border-radius: 10px;
        gap: 8px;
        margin-bottom: 16px;

        p {
            @include font-size(16, 24, 400);
        }
    }

    &__header {
        border: 1px solid $light-gray;
        border-radius: 10px;
        padding-top: 34px;
        margin-bottom: 16px;
        overflow: hidden;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 8px;
            padding: 0 34px;

            h1 {
                @include font-size(32, 34, 400, 1);
            }
        }

        &__details {
            display: flex;
            justify-content: space-between;
            padding: 0 34px;

            .type-year-km {
                @include font-size(14, 28, 400, 1);
                color: $gray;

                .type {
                    text-transform: uppercase;
                }
            }

            em {
                @include font-size(14, 28, 400, 1);
                color: $gray;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                img {
                    margin-left: 8px;
                    max-width: 20px;
                    max-height: 20px;
                }
            }
        }

        &__price {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 32px;
            width: 100%;

            h2 {
                @include font-size(48, 56, 400, -2);
                color: $primary-color;

                &.--monthly {
                    padding-bottom: 44px;

                    &:empty {
                        display: none;
                    }
                    
                    &::after {
                        content: ' por mes*';
                        vertical-align: baseline;
                        @include font-size(24, 28, 700, 0);
                    }
                }
            }
        }

        @include device-lg {
            padding-top: 24px;

            .vehicle-detail__header__top, .vehicle-detail__header__details  {
                padding: 0 12px;

                h1 {
                    @include font-size(24, 34, 400);
                }

                .type-year-km, em {
                    @include font-size(12, 28, 400);
                }
            }
        }

        &__status {
            padding: 0 34px 12px 34px;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: $gray;
        width: 100%;
        @include font-size(18, 28, 400);

        &.--disabled {
            pointer-events: none;

            .ego-form__control {
                color: $light-gray;
                pointer-events: none;
                cursor: not-allowed;
            }

            .ego-form__field.--checkbox label {
                pointer-events: none;
                cursor: not-allowed;
                @include font-size(14, 24, 400);
            }

            .btn-large, .btn-small {
                background-color: $light-gray;
                border-color: $light-gray;
                color: $gray;
                pointer-events: none;
                cursor: default;
            }

            .btn-price {
                pointer-events: none;
                border-color: $light-gray;
                background-color: transparent;
                color: $gray;

                &.--selected {
                    pointer-events: none;
                    background-color: $light-gray;
                    border-color: $light-gray;
                    color: $gray;
                }
            }
        }

        .payment-box {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 24px 16px;
            border-radius: 16px;
            position: relative;

            h4 {
                margin-bottom: 0;
            }

            hr {
                border: 0;
                border-top: 1px solid $mid-gray;
                margin: 0;
            }

            .pre-approval-box {
                display: flex;
                flex-direction: column;
                gap: 16px;
                overflow: hidden;
                height: 0;
                transition: height $timming ease-in-out;
            }

            .description {
                @include font-size(12, 18, 400);
                font-style: italic;
            }
            
            .btn-large.price-btn {
                border-color: $primary-color-light;
                padding: 4.5px 22px;
                text-transform: uppercase;
                border-radius: 0;
                width: max-content;

                span {
                    @include font-size(10, 20, 700);
                }

                &.payment-box__cash {
                    border-top-left-radius: 16px;
                    border-bottom-left-radius: 16px;

                    &.--rounded {
                        border-radius: 16px;
                        cursor: default;
                    }
                }

                &.payment-box__finance {
                    border-top-right-radius: 16px;
                    border-bottom-right-radius: 16px;

                    // .finance_price {
                    //     @include font-size(48, 56, 400, -2);
                    //     color: $primary-color;
                    //     position: absolute;
                    //     top: -50px;
                    //     left: 50%;
                    //     width: max-content;
                    //     transform: translateX(-50%);
                    // }
            
                    .finance_break {
                        position: absolute;
                        top: -24px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }

            .btn-large.price-btn.--selected {
                background-color: $primary-color-light;
                border-color: $primary-color-light;
                color: $primary-color;
            }

            .open-finance-modal {
                @include font-size(14, 29, 400, 0);
            }

            &__dealer {
                padding: 0 34px;
            }
        }

        .gray-box {
            background-color: #F1F1F1;
            width: 100%;
            padding: 24px 48px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button {
                width: 100%;
                margin-bottom: 12px;
                padding-top: 12px;
                padding-bottom: 12px;
            }

            @include device-lg {
                padding: 24px 30px;

                .ego-form__field__label {
                    @include font-size(14, 24, 400);
                }
            }
        }

        .ego-form__field.--checkbox {
            text-align: center;
            margin-bottom: 0;
        }

        .iva-text {
            margin-bottom: 0px;
            padding-top: 8px;
            border-top: 1px solid #EEEEEF;
            font-style: italic;
            color: $gray;
        }

        .vehicle-detail__commission {

            .iva-text {
                margin-bottom: 0px;
                padding-top: 8px;
                border-top: 1px solid #EEEEEF;
                font-style: italic;
                color: $gray;
            }

            .textlink {
                font-size: 12px;
                margin-top: 8px;
                margin-bottom: 0;
                text-align: end;
                text-decoration: underline;
                cursor: pointer;
                z-index: 2;
            }

            .detail {
                padding: 12px 0 6px;
                font-size: 12px;
                display: none;
                gap: 8px;
                p {
                    margin: 0;
                    display: flex;
                    justify-content: space-between;
                }
                &__final-price {
                    font-size: 14px;
                    font-weight: 800;
                }
                &.active {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    &__certified {
        border: 1px, solid $light-gray;
        display: flex;
        align-items: center;
        padding: 16px;
        column-gap: 16px;
        border-radius: 16px;
        margin-bottom: 16px;
        .icon {
            width: 80px;
            height: 80px;
            align-self: baseline;
        }
        &-text {
            color: $black;
            @include font-size(18, 28, 400);
            list-style: disc !important;

            li {
                display: flex;
                align-items: center;
                @include font-size(12, 18, 400);
            }
        }
        &.--mobile {
            flex-direction: row;
            margin-bottom: 12px;

            .icon {
                width: 70px;
                height: 70px;
                align-self: center;
            }
        }
        @include rwd(1024) {
            display: none;
        }
    }

    &__dealer {
        border: 1px, solid $light-gray;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 32px 23px;
        border-radius: 16px;
        margin-bottom: 16px;
        p {
            color: $gray;
            @include font-size(18, 28, 400);

            &.description {
                @include font-size(12, 18, 400);
                font-style: italic;
                max-width: 400px;
            }
        }
        @include rwd(1024) {
            display: none;
        }
    }

    &__favorite {
        display: flex;
        align-items: center;
        .favorite-btn {
            margin-left: 16px;
            padding: 0;
            background: none;
            border: 0;
            cursor: pointer;

            .heart {
                fill: none;         
            }
            
            &.filled {
                .heart {
                    fill: #3A00D5;
                }
            }
        }
    }

    &__gallery {
        grid-column: 1 /span 2;
        max-width: 100%;

        .swiper {
            &.gallery {
                margin-bottom: 16px;

                .swiper-slide {
                    border-radius: 18px;
                }

                .swiper-pagination {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    left: calc(50% - 32px);
                    width: 64px;
                    height: 35px;
                    @include font-size(16, 16, 700, 2);
                    background-color: rgba(255, 255, 255, 0.6);
                    border-radius: 20px;
                    padding-bottom: 2px;
                    bottom: 16px;
                }
    
                .swiper-button-next,
                .swiper-button-prev {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(255, 255, 255, 0.6);
                    font-weight: bold;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    
                    &:after {
                        font-size: 13px;
                        color: $black;
                        font-weight: bold;
                    }
                }

                @include device-md {
                    .swiper-slide {
                        border-radius: 11px;
                    }

                    .swiper-pagination {
                        font-size: 12px;
                        width: 45px;
                        height: 25px;
                        left: calc(50% - 25px);
                    }

                    .swiper-button-next,
                    .swiper-button-prev {
                        width: 25px;
                        height: 25px;
                        
                        &:after {
                            font-size: 8px;
                        }
                    }
                }
            }

            &.thumbs {
                .swiper-slide {
                    border-radius: 16px;
                    outline: 2px solid transparent;
                    outline-offset: -2px;
                    transition: outline-color $timming ease-in-out;

                    &-thumb-active {
                        outline-color: $primary-color;
                    }
                }
            }
        }

        @include device-lg {
            grid-column: 1;
            max-width: calc(100vw - 32px);
        }
    }

    &__description {
        border-top: 1px solid $light-gray;
        padding-top: 32px;

        .--bottomline {
            border: none;
            padding-bottom: 0;
            margin-bottom: 24px;
        }

        &.--desk {
            margin-top: 32px;
            .features_view_container {
                .feature_view{
                    width: 244px;

                    svg {
                        margin-right: 16px;
                    }

                    @include device-sm{
                        width: 100%;
                    }
                }
            }
            // &.features {
            //     .features_view_container {    
            //         .feature_view{
            //             width: 260px;
            //         }
            //     }
            // }
        }
        
        &.--mobile {
            .features_view_container {
                width: 100%;
                margin-bottom: 12px;
                gap: 4px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                svg {
                    margin-right: 16px;
                }

                p {
                    @include font-size(14, 20, 400);
                }

                .feature_view {
                    width: max-content;
                }
            }

            &.first {
                border: none;
                padding-top: 12px;
            }
        }

        @include device-lg {
            grid-column: 1;

            &.--desk {
                display: none;
            }
        }

        h4 {
            color: $gray;
        }

        .body {
            @include font-size(18, 28, 400);
        }
    }

    &__views-counter {
        margin-top: 40px;
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: $lighter-gray;
        font-size: 14px;
        border-radius: 8px;

        &.--mobile {
            flex-direction: row;
            height: max-content;
            margin-top: 0;
        }

        @include device-lg {
            margin-top: 32px;
            
            &.--desk {
                display: none;
            }
        }
    }
}
.features{
    margin-top: 32px;
}

#loginModal, #zoneModal {
    .ego-modal__box{
        &__content-header {
            p {
                margin: 8px 0 32px;
            }
        }

        &__close-btn {
            position: absolute;
            top: 0px;
            right: 0px;
            border: 0;
            background: none;
            outline: none;
            padding: 5px;
            margin: 0;
            display: flex;
            align-items: center;
            cursor: pointer;

            .times {
                width: 14px;
                height: 14px;

                * {
                    transition: stroke $timming ease-in-out;
                    stroke: $primary-color;
                }

            }

            &:hover {
                .times {
                    * {
                        stroke: $primary-color-darker;
                    }
                }
            }
        }

        #loginForm {
            margin-bottom: 32px;
            .text-link {
                display: block;
                text-align: center;
                margin: 0 auto 24px;
            }
            .btn-large {
                width: 100%;
            }
        }
        .--link-s {
            color: $primary-color;
        }
    }

}

#zoneModal {
    .ego-modal__wrapper {
        @include device-md {
            align-items: flex-start !important;
        }
    }
    .ego-modal__box{
        top: 50%;
        transform: translateY(-50%);
    
        &__content-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            
            svg {
                margin-bottom: 16px;
            }
            
            h2 {
                margin-bottom: 16px;
                @include font-size(20, 24, 700);
            }
            
            p {
                margin-bottom: 32px;
                @include font-size(16, 24, 400);
            }
        }

        .btn-large {
            width: 100%;
        }

        @include device-md {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

}