.certification {
    background-color: #F7F7F7;
    
    &_text {
        text-align: center;
        max-width: 630px;
        margin: auto;

        .subtitle {
            color: $primary-color;
            margin-bottom: 8px;
        }

        h2 {
            margin-bottom: 16px;
        }

        .text {
            margin-bottom: 40px;
        }
    }

    &_boxes {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 2em 1em;
        justify-items: center;

        .box {
            text-align: center;

            img {
                width: 140px;
                height: 140px;
            }

            .title {
                margin-top: 24px;
                margin-bottom: 16px;
            }
        }
    }

    &_button {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }
}