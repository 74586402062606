.content-center {
    @include main-grid();
    min-height: 100vh;
    align-content: center;

    &_text {
        text-align: center;
        padding-top: 92px;
        margin-left: auto;
        margin-right: auto;
        grid-column: 7 / span 11;
        grid-row: 1;

        h1 {
            margin-bottom: 12px;
        }

        p {
            margin-bottom: 24px;
            color: $gray;
        }

        @include device-lg(){
            grid-column: 1 / -1;
        }
    }

    .contactForm {
        grid-column: 7 / span 11;
        grid-row: 2;
        display: grid;
        text-align: center;

        .ego-form__field {
            max-width: 400px;
            min-width: 343px;
            margin-left: auto;
            margin-right: auto;
        }

        .btn {
            button {
                max-width: 400px;
                min-width: 343px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .link {
            margin-top: 16px;
        }

        @include device-lg(){
            grid-column: 1 / -1;
        }
    }

    &_buttons {
        grid-column: 9 / span 7;
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        justify-content: center;
        align-items: center;

        p {
            text-align: center;
            span {
                strong {
                    color: #E30018;
                }
            }
        }

        a {
            margin-top: 16px;
            min-width: 343px;
            max-width: 400px;
        }

        @include device-lg(){
            grid-column: 1 / -1;

            p {
                display: flex;
                flex-direction: column;
            }
        }
    }
}