.ellipsis {
    position: relative;
    display: block;
    width: 32px;

    $ellipsis: &;

    &__button {
        display: inline-flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 0;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color $timming ease-in-out;

        #{$ellipsis}__dots {
            width: 4px; 
            height: 18px;
    
            * {
                stroke: $primary-color;
                transition: stroke $timming ease-in-out;
            }
        }

        &:hover {
            background-color: $lighter-gray;

            #{$ellipsis}__dots {
                * {
                    stroke: $primary-color-darker;
                }
            }
        }
    }


    &__dropdown {
        position: absolute;
        top: calc(100% + 6px);
        left: 0;
        z-index: 1;
        min-width: 160px;
        background-color: $white;
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
        border: 1px solid $light-gray;
        border-radius: 8px;
        padding: 2px 0;
        margin-left: -3px;
        margin-top: 8px;
        max-height: 250px;
        overflow-x: auto;
        pointer-events: none;
        opacity: 0;
        transition: opacity $timming ease-in-out, color $timming ease-in-out, background-color $timming ease-in-out;
        
        @include custom-scrollbars;

        ul {
            display: flex;
            flex-direction: column;

            li {
                padding: 8px 0;

                &:not(:last-child) {
                    border-bottom: 1px solid $light-gray;
                }
            }
        }

        &__btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border: 0;
            text-align: left;
            cursor: pointer;
            padding: 8px 16px;
            background-color: transparent;
            @include font-size(14, 16);
            transition: background-color $timming ease-in-out;

            &:hover {
                background-color: #F7F7F7;
            }
        }
    }

    &.--open {
        #{$ellipsis}__dropdown {
            opacity: 1;
            pointer-events: all;
        }
    }

    &.--right {
        margin-left: auto;

        #{$ellipsis}__dropdown {
            left: auto;
            right: 0;
        }
    }
}