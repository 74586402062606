.registrate {
    @include main-grid();
    // padding-top: 92px;
    // padding-bottom: 92px;
    min-height: 100vh;
    align-content: center;

    &_text {
        text-align: center;
        padding-top: 92px;
        margin-left: auto;
        margin-right: auto;
        grid-column: 7 / span 11;
        grid-row: 1;

        h1 {
            margin-bottom: 12px;
        }

        p {
            margin-bottom: 24px;
            color: $gray;
        }

        @include device-lg(){
            grid-column: 1 / -1;
        }
    }


    .contactForm {
        grid-column: 5 / span 15;
        grid-row: 2;
        display: grid;
        column-gap: 16px;
        text-align: center;
        grid-template-columns: repeat(2, 1fr);

        .--izq {
            grid-column: 1;
        }

        .--der {
            grid-column: 2;
        }

        .full-width {
            grid-column: 1 / span 2 !important;
        }

        .create {
            margin-top: 8px;
            margin-bottom: 16px;
            color: $gray;
        }

        .check {
            margin-top: 8px;
            color: $gray;
            display: flex;
            align-items: center;
            
            label {
                margin-left: 10px;
                span {
                    a {
                        pointer-events: auto;
                        color: $primary-color-lighter;
                    }
                }
            }

            @include device-sm {
                text-align: left;
                display: flex;
                align-items: flex-start;

                span {
                    margin-left: 8px;
                }
            }
        }

        .registrate-check-label {
            a {
                pointer-events: auto;
                color: $primary-color-lighter;
            }
        }
        .btn {
            button {
                width: 400px;
                margin-top: 40px;
                margin-left: auto;
                margin-right: auto;

                @include device-sm {
                    width: 100%;
                }
            }
        }

        .link {
            margin-top: 16px;
        }

        @include device-lg(){
            grid-column: 1 / -1;
        }

        @include device-sm(){
            div:nth-child(2n+1) {
                grid-column: 1 / span 2;
            }
    
            div:nth-child(2n) {
                grid-column: 1 /span 2;
            }
        }
    }

    .back-to-profile {
        display: flex;
        grid-column: 1 / -1;
        grid-row: 1;
        align-self: start;
        margin-top: 40px;

        a {
            color: $primary-color;
            margin-left: 12px;
            text-decoration: underline;
        }

        @include device-lg(){
            grid-row: 1;
        }
    }

    .warning-mail {
        background-color: #F1ECFF;
        margin-bottom: 40px;
        display: flex;
        padding: 8px 18px;
        border-radius: 8px;
        align-items: center;

        img {
            width: 16px;
            height: 16px;
            margin-right: 18px;
        }

        p {
            color: $primary-color;
            line-height: 17px;
        }

        @include device-md(){
            img {
                margin-right: 8px;
            }

            p {
                font-size: 12px;
            }
        }
    }

    .contactForm {
        &1 {
            grid-row: 2;
            .btn-large {
                margin-top: 0 !important;
                margin-bottom: 40px;
            }
        }

        &2 {
            grid-row: 3;
            .btn-large {
                margin-top: 0 !important;
                margin-bottom: 40px;
            }
        }

        &3 {
            grid-row: 4;
            .btn-large {
                margin-top: 0 !important;
                margin-bottom: 40px;
            }
        }
    }
}