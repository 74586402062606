.upper-section {
    position: relative;
    min-height: 1250px;
    background-size: auto 100%;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-color: #F7F7F7;

    @include device-lg{
        min-height: 1400px;
        background-size: auto 70%;
        background-position: bottom center;

        &::after {
            content: '';
            position: absolute;
            inset: 0 0 auto 0;
            height: 500px;
            background: linear-gradient(0deg, rgba(25, 25, 25, 0) 6%, #191919 22%);
            z-index: 0;
        }
    }

    @include device-md {
        &::after {
            height: 690px;
        }
    }

    @include device-sm {
        background-size: 900px auto;
        min-height: 1180px;

        &::after {
            height: 790px;
        }
    }
    
    img {
        width: 100%;
        height: auto;
    }

    &_container {
        @include main-grid();

        &_title {
            position: relative;
            z-index: 1;
            grid-column: 5 / span 15;
            grid-row: 1;
            text-align: center;
            color: $white;
            margin-bottom: 24px;
            margin-top: 80px;

            @include device-lg{
                grid-column: 1 / last-col;
            }
            @include device-md{
                margin-top: 40px;
            }
            @include device-sm{
                font-size: 26px;
            }
        }

        &_subtitle {
            position: relative;
            z-index: 1;
            grid-column: 5 / span 15;
            grid-row: 2;
            text-align: center;
            color: $white;
            margin-bottom: 40px;
            font-size: clamp(18px, 3vw, 24px);
            line-height: 1.2;
            font-weight: 400;

            @include device-lg{
                grid-column: 1 / last-col;
            }
            @include device-md{
                margin-top: 40px;
            }
            @include device-sm{
                margin-top: 0px;
            }
        }


        .budget-tabs {
            position: relative;
            z-index: 1;
            grid-column: 3 / span 19;
            grid-row: 3;
            border-radius: 40px;
            background-color: $white;
            overflow: hidden;

            @include rwd(1400){
                grid-column: 1 / last-col;
            }
            @include device-lg{
                grid-column: 1 / last-col;
            }
            @include device-sm{
                display: flex;
                flex-direction: column;
                border-radius: 30px;
            }

            &__header {
                display: grid;
                grid-template-columns: 1fr 1fr;
                border-bottom: 1px solid $mid-gray;

                &__btn {
                    display: block;
                    width: 100%;
                    background-color: $white;
                    border: 0;
                    border-bottom: 3px solid;
                    border-bottom-color: $white;
                    padding: 16px 10px 10px;
                    @include font-size(16, 22, 700);
                    color: $gray;
                    cursor: pointer;
                    transition: border-color $timming ease-in-out, color $timming ease-in-out;

                    &.--active {
                        border-bottom-color: $primary-color;
                        color: $primary-color;
                    }
                }
            }

            &__tab {
                @include main-grid();
                display: none;
                padding: 40px;
                
                @include device-lg{
                    text-align: center;
                }

                @include device-sm {
                    padding: 40px 20px;
                }
    
                &.--active {
                    display: grid;
                }
                
                &.--block {
                    pointer-events: none;
                }
    
                .column1 {
                    grid-column: 1 / span 14;
                    display: grid;
    
                    @include device-sm{
                        display: flex;
                        flex-direction: column;
                    }
                    .row1 {
                        grid-row: 1;
    
                        .--title-s {
                            display: block;
                            margin-bottom: 16px;
                        }
                        
                        .inputs {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 16px;
                            column-gap: 10px;
                            @include device-lg{
                                flex-wrap: wrap;
                            }
                        }
                        .ego-form__field{
                            margin-bottom: 30px;
                        }
                    }
    
                    .row2 {
                        grid-row: 2;
                        border-top: 1px solid #DEDEDF;
                        padding-top: 16px;
                        @include device-sm{
                            border-top: unset;
                        }
    
                        @include device-lg{
                            margin-bottom: 25px;
                        }
    
                        &-title {
                            margin-bottom: 16px;
                            @include device-sm{
                                text-align: center;
                            }
                        }
    
                        .upper-section-swiper {
                            width: 100%;
                            height: 100px;
                            max-width: 630px;
    
                            @include device-md{
                                max-width: 530px;
                                height: 100px;
                            }
                            @include device-sm{
                                max-width: 300px;
                                height: 100px;
                            }
                            .swiper-wrapper{
                                .swiper-slide {
                                    text-decoration: none;
                                    text-align: center;
                                    font-size: 18px;
                                    background: #fff;
                                    display: flex;
                                    flex-flow: column nowrap;
                                    align-items: center;
                                    justify-content: center;
                                    
                                    height: 85px;
                                    padding: 8px;
                                    border: 1px solid #DEDEDF;
                                    border-radius: 16px;
                                    text-align: center;
                                    margin-right: 12px!important;
                                    cursor: pointer;
                                    transition: border 300ms ease-in-out;
    
                                    &:hover,
                                    &.--active {
                                        border: 1px solid #5A3FEE;
                                    }
                                    img {
                                        display: block;
                                        width: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                            .swiper-pagination{
                              bottom: 0px;
                              .swiper-pagination-bullet-active{
                                  background-color: $primary-color;
                              }
                            }
                        }
                    }
                }
    
                .column2 {
                    grid-column: 16 / -1;
                    text-align: center;
                    
                    button {
                        margin-left: auto;
                        margin-right: auto;
                    }
    
                    @include device-lg {
                        grid-column: 1/span 14;
                    }
    
                    @include device-sm {
                        width: 100%;
                    }
    
                    .--title-m {
                        display: block;
                        margin-bottom: 8px;
                    }
    
                    h1 {
                        margin-bottom: 49px;
                    }
                    a {
                        display: block;
                    }
    
                    @include device-lg{
                        h1 {
                            margin-bottom: 16px;
                        }
                    }
                }
            }

            #homeBudgetForm {
                .ego-form__field {
                    @include device-lg('min') {
                        max-width: 50%;
                    }
                }
            }
        }
    }
}