.how-it-works {
    background-size: cover;
    background-position-x: center;
    background-color: hsl(0, 0%, 97%);
    $parent: &;
    
    .wrapper {
        position: relative;
        @include main-grid();

        @include device-lg {
            display: block;
        }
    }

    &_head {
        grid-column: 2 / span 20;
        margin-bottom: 40px;

        h3 {
            @include font-size(32, 40, 700, -2);

            @include device-md {
                @include font-size(26, 34, 700, -2);
            }
        }
    }

    &_content {
        grid-column: 1 / span 22;
        @include main-grid();
        justify-items: end;
        row-gap: 50px;
        padding: 63px 0;
        border-radius: 80px;
        background: 
            linear-gradient(270deg, rgba(25, 25, 25, 0.03) 16.92%, rgba(25, 25, 25, 0.88) 94.33%),
            url('../images/home/how-it-works/asphalt.jpg') no-repeat center center;
        background-size: cover;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            z-index: 1;
            background: url('../images/home/how-it-works/car2.png') no-repeat bottom right;
            background-size: 63%;
            border-radius: 0 0 80px 80px;
        }

        @include rwd(1200) {
            &::after {
                background-size: 57%;
            }
        }

        @include rwd(1180) {
            padding-bottom: 120px;
        }
        
        @include device-lg {
            display: block;
            padding: 80px 50px;
            border-radius: 40px;

            &::after {
                display: none;
            }
        }

        @include device-sm {
            padding: 50px 16px;
        }

        #{$parent}_list{
            position: relative;
            z-index: 2;
            grid-column: 2 / span 18;
            display: grid;
            grid-template-columns: 390px 390px;
            width: 100%;
            max-width: 890px;
            justify-content: space-between;
            gap: 10%;

            @include rwd(1180) {
                grid-template-columns: 60%;
                gap: 16px;
            }

            @include device-lg {
                grid-template-columns: 100%;
            }

            @include device-sm{
                width: 100%;
            }

            #{$parent}_item {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }

                &:last-child {
                    padding-right: 8px;
                }

                #{$parent}_table{
                    color: $white;
                    @include font-size(12, 18, 400);
                    margin-top: 8px;

                    .table-head {
                        color: $black;
                        background-color: $mid-gray;
                        border-radius: 10px;
                        padding: 5px 22px;
                        display: grid;
                        grid-template-columns: 190px 120px;
                        gap: 16px;
                        align-items: center;
                        font-weight: 700;
                    }

                    .table-content {
                        display: grid;
                        grid-template-columns: 190px 120px;
                        gap: 16px;
                        align-items: center;
                        padding: 8px 24px;
                        border-bottom: 1px solid $white;

                        &:last-child {
                            border-bottom: none;
                        }
                    }

                    @include device-md {
                        .table-content, .table-head {
                            grid-template-columns: 50% 35%;
                            justify-content: center;
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        #{$parent}_button{
            position: relative;
            z-index: 2;
            grid-column: 12/span 11;
            align-self: end;

            @include device-lg{
                margin-top: 60px;

                .btn-large {
                    margin: auto;
                    max-width: 300px;
                }
            }

            @include device-sm{
                margin-top: 30px;

                .btn-large {
                    max-width: none;
                }
            }
        }

        #{$parent}_head {
            p {
                color: $primary-color;
                margin-bottom: 8px;
            }
    
            h2 {
                margin-bottom: 40px;
            }
        }

        .title {
            display: flex;
            border-bottom: 1px solid #DEDEDF;
            padding-bottom: 8px;
            margin-bottom: 8px;
            color: $white;

            img {
                transform: rotate(180deg);
                margin-right: 8px;
            }
        }
        .text{
            color: $white;
        }
        .--title-s {
            font-weight: 700;
        }
    }
}