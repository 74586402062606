.comissions_table {
    color: $black;
    @include font-size(12, 18, 400);
    margin-top: 24px;

    .table-head {
        background-color: $mid-gray;
        border-radius: 10px;
        display: grid;
        align-items: center;
        font-weight: 700;
    }

    .table-content {
        display: grid;
        align-items: center;
        padding: 8px 24px;
        border-bottom: 1px solid $mid-gray;

        &:last-child {
            border-bottom: none;
        }
    }

    &.b2c {
        .table-head, .table-content {
            grid-template-columns: 1fr 1fr;
            padding: 5px 22px;
            gap: 16px;
        }
    }

    &.c2c {
        width: max-content;
        padding-bottom: 8px;
        width: 100%;

        .table-head, .table-content {
            grid-template-columns: 155px 114px 105px 105px;
            padding: 5px 11px;
            gap: 10px;
            justify-content: space-between;
            width: max-content;
        }
    }
}